import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { IContextualMenuItem } from "@fluentui/react";
import React, { useCallback, useState, useEffect } from "react";

import { useAppRouteId, useAppRouteParams } from "../../../../AppRoutes";
import { useContextMenuItemsViewsMenu } from "../../../../contextItems/hooks";
import { useUpdateView, useViews } from "../../../../domain/View";
import { ManagedTransaction } from "../../../../firebase/firestore";
import { useAppNotificationManager } from "../../../AppProvider/AppNotificationProvider";
import { useDialogContextMenuItemsView } from "../../../Dialog/DialogFirestore/DialogView/hooks";
import { SortableTmpMenuSet } from "../../helpers";

import { LoadingCoverWithoutText } from "@/components/LoadingCover";
import { useVersionPermission } from "@/hooks/useCollectionPermission";

/**
 * @description View一覧を表示し、編集や新規作成の操作機能を提供
 */
export const ViewsMenu: React.FC = () => {
  const appRouteId = useAppRouteId();
  const { organizationId, versionId, viewId } = useAppRouteParams();
  const [hasVersionWritePermission, isLoadedPermission] =
    useVersionPermission("write");
  const [views, isLoadedViews] = useViews({});
  const sortedViews = [...views].sort((a, b) => Math.sign(a.sort - b.sort));

  const updateView = useUpdateView();
  const [isCloseViews, setIsCloseViews] = useState(false);

  const contextMenuItemsViews: IContextualMenuItem[] =
    useContextMenuItemsViewsMenu({});
  const { renderDialogsView, getContextMenuItemsView } =
    useDialogContextMenuItemsView();

  /**
   * dnd-kit
   */
  const [displayViews, setDisplayViews] = useState(sortedViews);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const { showErrorNotification } = useAppNotificationManager();

  const handleDragEnd = useCallback(
    async (event: any) => {
      const { active, over } = event;
      if (active.id !== over.id) {
        const oldIndex = displayViews.findIndex(
          (item) => item.id === active.id
        );
        const newIndex = displayViews.findIndex((item) => item.id === over.id);
        const newViews = arrayMove(displayViews, oldIndex, newIndex).map(
          (view, index) => ({ ...view, sort: index })
        );
        setDisplayViews(newViews);
        try {
          await ManagedTransaction.runTransaction(async (transaction) => {
            for (const view of newViews) {
              await updateView(
                {
                  ...view,
                },
                { versionId, viewId: view.id },
                { transaction }
              );
            }
          });
        } catch (error) {
          showErrorNotification("Viewのソートに失敗しました。", error);
          setDisplayViews(displayViews);
        }
      }
    },
    [displayViews]
  );
  // NOTE: Viewの書き込みが終わる直前のちらつきを抑える
  useEffect(() => {
    setDisplayViews(
      [...views].sort((a, b) => {
        return Math.sign(a.sort - b.sort);
      })
    );
  }, [views]);

  if (!isLoadedViews || !isLoadedPermission) {
    return <LoadingCoverWithoutText />;
  }
  return (
    <>
      <SortableTmpMenuSet
        id={"views"}
        text={"ビュー"}
        iconName={isCloseViews ? "ChevronDown" : "ChevronUp"}
        onClick={() => {
          setIsCloseViews(!isCloseViews);
        }}
        contextMenuItems={contextMenuItemsViews}
      />
      {!isCloseViews && (
        <div>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={async (e) => {
              await handleDragEnd(e);
            }}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              items={displayViews.map(({ id }) => id)}
              disabled={!hasVersionWritePermission}
            >
              {!isCloseViews &&
                displayViews.map((view) => (
                  <SortableTmpMenuSet
                    id={view.id}
                    key={view.id}
                    text={view.title}
                    to={`/organizations/${organizationId}/versions/${versionId}/views/${view.id}`}
                    active={appRouteId === "view" && viewId === view.id}
                    contextMenuItems={getContextMenuItemsView(view)}
                    isSortable={hasVersionWritePermission}
                  />
                ))}
            </SortableContext>
          </DndContext>
        </div>
      )}
      {renderDialogsView(sortedViews)}
    </>
  );
};
