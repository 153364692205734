import loadable from "@loadable/component";
import { ViewType } from "../../../../../../domain/ViewType";
import { MasterSheetsType } from "../../types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDepotInputView: ViewType<ViewTypeDepotInputView, ""> = {
  key: "cptdomDepotInputView",
  displayName: "CPT-DOM 油槽所現地入力ビュー",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "config",
      displayName: "設定",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
      ],
    },
    {
      key: "portMaster",
      displayName: "基地マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "port_name",
          displayName: "port_name",
          type: "string",
        },
      ],
    },
    {
      key: "oilMaster",
      displayName: "油種マスタ",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "oil_name",
          displayName: "oil_name",
          type: "string",
        },
      ],
    },
    {
      key: "dtTankMaster",
      displayName: "油槽所タンクマスタ",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "capacity",
          displayName: "capacity",
          type: "float",
        },
        {
          key: "dead",
          displayName: "dead",
          type: "float",
        },
        {
          key: "since",
          displayName: "since",
          type: "date",
        },
        {
          key: "label",
          displayName: "label",
          type: "string",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "companyMaster",
      displayName: "会社マスタ",
      dependSheetFields: [
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
        {
          key: "company_name",
          displayName: "company_name",
          type: "string",
        },
      ],
    },
    {
      key: "dtReceivingColumnControlMaster",
      displayName: "受入列制御マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
        {
          key: "ship_owner_company_code",
          displayName: "ship_owner_company_code",
          type: "string",
        },
        {
          key: "is_shown",
          displayName: "is_shown",
          type: "boolean",
        },
      ],
    },
    {
      key: "dtRunningInput",
      displayName: "油槽所操業入力",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "cosmo_receiving_amount_from_cosmo",
          displayName: "cosmo_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "cosmo_receiving_amount_from_eneos",
          displayName: "cosmo_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "cosmo_receiving_amount_from_idemitsu",
          displayName: "cosmo_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_cosmo",
          displayName: "eneos_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_eneos",
          displayName: "eneos_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_idemitsu",
          displayName: "eneos_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_cosmo",
          displayName: "idemitsu_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_eneos",
          displayName: "idemitsu_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_idemitsu",
          displayName: "idemitsu_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "shipping_amount_cosmo",
          displayName: "shipping_amount_cosmo",
          type: "float",
        },
        {
          key: "shipping_amount_eneos",
          displayName: "shipping_amount_eneos",
          type: "float",
        },
        {
          key: "shipping_amount_idemitsu",
          displayName: "shipping_amount_idemitsu",
          type: "float",
        },
        {
          key: "actual_stock_cosmo",
          displayName: "actual_stock_cosmo",
          type: "float",
        },
        {
          key: "actual_stock_eneos",
          displayName: "actual_stock_eneos",
          type: "float",
        },
        {
          key: "actual_stock_idemitsu",
          displayName: "actual_stock_idemitsu",
          type: "float",
        },
        {
          key: "port_code_yyyymm",
          displayName: "port_code_yyyymm",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};

export type ViewTypeDepotInputView = {
  config: {
    port_code: string;
    date: string;
  };
  portMaster: {
    port_code: string;
    port_name: string;
  };
  oilMaster: {
    oil_code: string;
    oil_name: string;
  };
  dtTankMaster: {
    id: string;
    port_code: string;
    oil_code: string;
    capacity: string;
    dead: string;
    since: string;
    label: string;
    display_order: string;
  };
  companyMaster: {
    company_code: string;
    company_name: string;
  };
  dtReceivingColumnControlMaster: {
    port_code: string;
    oil_code: string;
    company_code: string;
    ship_owner_company_code: string;
    is_shown: string;
  };
  dtRunningInput: {
    id: string;
    port_code: string;
    oil_code: string;
    date: string;
    cosmo_receiving_amount_from_cosmo: string;
    cosmo_receiving_amount_from_eneos: string;
    cosmo_receiving_amount_from_idemitsu: string;
    eneos_receiving_amount_from_cosmo: string;
    eneos_receiving_amount_from_eneos: string;
    eneos_receiving_amount_from_idemitsu: string;
    idemitsu_receiving_amount_from_cosmo: string;
    idemitsu_receiving_amount_from_eneos: string;
    idemitsu_receiving_amount_from_idemitsu: string;
    shipping_amount_cosmo: string;
    shipping_amount_eneos: string;
    shipping_amount_idemitsu: string;
    actual_stock_cosmo: string;
    actual_stock_eneos: string;
    actual_stock_idemitsu: string;
    port_code_yyyymm: string;
  };
};

export type DepotInputViewStrictViewType = ViewType<ViewTypeDepotInputView, "">;

export type DepotInputViewSheetsData = React.ComponentProps<
  DepotInputViewStrictViewType["render"]
>["sheetsData"];
