import React from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import {
  Version,
  useSetVersion,
  useUpdateVersion,
} from "../../../../../domain/Version";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { ReferredFromState, getDialogMessages } from "../../helper";

export const DialogVersionDelete: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
    isIncludingReferencedSheets: ReferredFromState;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  isIncludingReferencedSheets,
  dialogPhase,
  setDialogPhase,
}) => {
  const { organizationId, versionId } = useAppRouteParams();
  const navigate = useNavigate();
  const updateVersion = useUpdateVersion();

  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const selectedVersion = versions.find(
    ({ id }) => id === selectedVersionIds[0]
  );

  const runDelete = async () => {
    if (selectedVersion) {
      try {
        setDialogPhase("in_progress");
        await updateVersion(
          {
            ...selectedVersion,
            isDisabled: true,
          },
          {}
        );
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  };

  const messages = getDialogMessages({
    isSome: false,
    actionType: "delete",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDelete,
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
          isThemeRed: true,
          ...messages.confirm,
          displayMessage:
            isIncludingReferencedSheets === "including"
              ? "他のシートから参照されているシートが含まれるため、表示が破壊されることがあります。削除しますか？"
              : "バージョンを削除しますか？",
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
            if (selectedVersionIds.includes(versionId)) {
              navigate(`/organizations/${organizationId}`);
            }
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};
