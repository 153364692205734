import {
  ICheckStyles,
  IComboBoxStyles,
  IContextualMenuStyles,
  IDatePickerStrings,
  IDatePickerStyles,
  IDropdownStyles,
  IPivotStyles,
  ITextFieldStyles,
  IToggleStyles,
} from "@fluentui/react";
import { makeStyles, shorthands } from "@fluentui/react-components";

// NOTE: disableの時だけ色がダークになる設定
export const contextMenuStyle: Partial<IContextualMenuStyles> = {
  root: {
    minWidth: 0,
  },
};

export const comboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    width: "250px",
  },
  container: { marginBottom: "32px" },
};
export const heavyComboBoxStyles: Partial<IComboBoxStyles> = {
  root: {
    width: "250px",
    marginBottom: "32px",
  },
  label: {
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export const checkboxStyles: Partial<ICheckStyles> = {
  root: {
    margin: "25px 0px",
  },
};

export const pivotOutsideContentStyles: Partial<IPivotStyles> = {
  root: {
    backgroundColor: "var(--figma-color-snowWhite)",
    marginBottom: "10px",
  },
  linkContent: {
    height: "33px",
  },
  link: [
    {
      fontSize: "14px",
      fontWeight: "300",
      paddingBottom: "13px",
      ":hover": {
        backgroundColor: "var(--figma-color-line-blueGray)",
      },
    },
  ],
  linkIsSelected: [
    {
      fontSize: "14px",
      fontWeight: "600",
      color: "var(--figma-color-theme-primary)",
      ":hover": {
        color: "var(--figma-color-theme-primary)",
        backgroundColor: "var(--figma-color-line-blueGray)",
      },
    },
  ],
};

export const pivotInsideContentStyles: Partial<IPivotStyles> = {
  root: {
    backgroundColor: "var(--figma-color-offWhite)",
    marginBottom: "10px",
    borderBottom: "1px solid #ccc",
  },
  linkContent: {
    height: "33px",
  },
  link: [
    {
      fontSize: "14px",
      fontWeight: "300",
      padding: "0 24px",
      paddingBottom: "13px",
      ":hover": {
        backgroundColor: "var(--figma-color-line-blueGray)",
      },
    },
  ],
  linkIsSelected: [
    {
      fontSize: "14px",
      fontWeight: "600",
      color: "var(--figma-color-theme-primary)",
      ":hover": {
        color: "var(--figma-color-theme-primary)",
        backgroundColor: "var(--figma-color-line-blueGray)",
      },
    },
  ],
};

export const getVerticalTextFieldStylesByWidth = (
  width: number
): Partial<ITextFieldStyles> => ({
  subComponentStyles: {
    label: {
      root: {
        flex: 1,
        fontWeight: "normal",
        paddingTop: "0px",
        paddingBottom: "9px",
        width: `${width}px`,
      },
    },
  },
  wrapper: {
    margin: "15px 0",
    padding: "0",
  },
  fieldGroup: {
    width: `${width}px`,
  },
});
export const verticalTextFieldStyles: Partial<ITextFieldStyles> =
  getVerticalTextFieldStylesByWidth(250);

export const horizontalTextFieldStyles: Partial<ITextFieldStyles> = {
  subComponentStyles: {
    label: {
      root: {
        flex: 1,
        fontWeight: "normal",
        padding: "10px 0",
        width: "150px",
      },
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    padding: "0",
  },
  fieldGroup: {
    width: "130px",
  },
};

export const toggleStyles: Partial<IToggleStyles> = {
  root: {
    display: "flex",
    alignItems: "center",
    margin: "0px",
    padding: "0px",
  },
  label: {
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "14px",
    lineHeight: "32px",
    fontWeight: "normal",
    color: "inherit",
    paddingRight: "5px",
  },
};

export const getVerticalDropdwnStylesByWidth = (
  width: number
): Partial<IDropdownStyles> => ({
  root: {
    marginTop: "10px",
    marginBottom: "15px",
    padding: "0",
  },
  label: {
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "14px",
    fontWeight: "inherit",
    color: "#4F4F4F",
  },
  dropdown: {
    width: `${width}px`,
  },
});
export const verticalDropdownStyles = getVerticalDropdwnStylesByWidth(250);

export const horizontalDropdownStyles: Partial<IDropdownStyles> = {
  root: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",
    padding: "0",
  },
  label: {
    flex: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    fontSize: "14px",
    lineHeight: "32px",
    fontWeight: "inherit",
    color: "#4F4F4F",
  },
  dropdown: {
    width: "130px",
  },
};

export const datePickerStyles: Partial<IDatePickerStyles> = {
  wrapper: {
    margin: "15px 0",
  },
};
export const datePickerTextFieldStyles: Partial<ITextFieldStyles> = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    // padding: "0 24px",
  },
  subComponentStyles: {
    label: {
      root: {
        flex: 1,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: "14px",
        lineHeight: "32px",
        fontWeight: "normal",
        color: "#4F4F4F",
      },
    },
  },
  fieldGroup: {
    width: "130px",
  },
};

export const datePickerStrings: IDatePickerStrings = {
  months: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],

  shortMonths: [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ],

  days: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],

  shortDays: ["日", "月", "火", "水", "木", "金", "土"],

  goToToday: "今日に移動",
  prevMonthAriaLabel: "前月に移動",
  nextMonthAriaLabel: "次月に移動",
  prevYearAriaLabel: "前年に移動",
  nextYearAriaLabel: "次年に移動",
  closeButtonAriaLabel: "カレンダーを閉じる",
  monthPickerHeaderAriaLabel: "{0}, select to change the year",
  yearPickerHeaderAriaLabel: "{0}, select to change the month",

  isRequiredErrorMessage: "このフィールドは必須です",

  invalidInputErrorMessage: "日付が正しく入力されていません",
};

export const useDialogStyles = makeStyles({
  dialog: {
    width: "max-content",
    maxWidth: "90%",
    maxHeight: "100%",
  },
});

export const useWarningButtonStyles = makeStyles({
  warning: {
    backgroundColor: "#d83b01",
    color: "#fff",
    ...shorthands.border("none"),
    ":hover:not(:disabled)": {
      backgroundColor: "#c33400",
      color: "#fff",
    },
    ":disabled": {
      backgroundColor: "#F0F0F0",
      color: "#BDBDBD",
      cursor: "not-allowed",
    },
  },
});

export const truncatedTextStyles = {
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
};
