import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import { TypeSchema, TypeSchemaRaw } from "./TypeSchema";

// todo ゆくゆくはtypeは配列だけにしたい
/**
 * @param id firestore上の識別子
 * @param displayName 依存シートの表示名。ViewTypeのdependSheets.dependSheetFields.displayNameに相当
 * @param key フロント上の識別子。ViewTypeのdependSheets.dependSheetFields.keyに相当
 * @param type スキーマ。ViewTypeのdependSheets.dependSheetFields.type。単体でも、リストでも指定可能
 * @param sheetFieldId 対象のスキーマID
 * @param dependSheetId 紐づく依存シート設定ID
 */
export type ViewDependSheetField<K extends string = string> = {
  id: string;
  key: K;
  displayName: string;
  sheetFieldId: string;
  type: TypeSchema["type"];
};
export const checkViewDependSheetFieldHasBlankId = (
  v: ViewDependSheetField
) => {
  return v.id === "" || v.sheetFieldId === "";
};
type ViewDependSheetFieldRaw = {
  id: string;
  key: string;
  display_name: string;
  sheet_field_id: string;
  field_type: TypeSchemaRaw["field_type"];
};

const processorOptions: FirestoreCollectionProsessorOptions<
  ViewDependSheetField,
  ViewDependSheetFieldRaw,
  { dependSheetId: string }
> = {
  getCollectionPath: ({ organizationId, versionId, viewId, dependSheetId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/views/${viewId}/view_depend_sheets/${dependSheetId}/view_depend_sheet_fields`,
  toFirestore: ({ id, displayName, key, type, sheetFieldId }) => ({
    id,
    display_name: displayName,
    key,
    field_type: type,
    sheet_field_id: sheetFieldId,
  }),
  fromFirestore: ({ id, display_name, key, field_type, sheet_field_id }) => ({
    id,
    displayName: display_name,
    key,
    type: field_type,
    sheetFieldId: sheet_field_id,
  }),
};

export const {
  useGetAll: useGetViewDependSheetFields,
  useSet: useSetViewDependSheetField,
  useUpdate: useUpdateViewDependSheetField,
  useDelete: useDeleteViewDependSheetField,
  useItems: useViewDependSheetFields,
  useSomeItems: useSomeViewDependSheetFields,
} = createFirestoreCollectionProsessor(processorOptions);
