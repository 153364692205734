import { OrganizationRaw } from "@/domain/Organization";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

import { Version } from "@/domain/Version";

const apiUrl = "/versions";

// バージョンを複製
export const postDuplicateVersion = async ({
  organizationId,
  versionId,
  title,
}: {
  organizationId: OrganizationRaw["org_id"];
  versionId: Version["id"];
  title: string;
}): Promise<{ new_version_id: string }> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ new_version_id: string }>(
    `${apiUrl}/${versionId}/copy`,
    { title },
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data;
};
