import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";

const inOrganizationContext = createContext<{
  versions: {
    selectedCategory: string;
    setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
    filteringText: string;
    setFilteringText: React.Dispatch<React.SetStateAction<string>>;
    isShowDisabledVersions: boolean;
    setIsShowDisabledVersions: React.Dispatch<React.SetStateAction<boolean>>;
  };
}>({} as never);

// 組織内でのフィルタ設定などを管理するプロバイダ
export const InOrganizationContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("all");
  const [filteringText, setFilteringText] = useState<string>("");
  const [isShowDisabledVersions, setIsShowDisabledVersions] =
    useState<boolean>(false);

  const ctxValue = useMemo(
    () => ({
      versions: {
        selectedCategory,
        setSelectedCategory,
        filteringText,
        setFilteringText,
        isShowDisabledVersions,
        setIsShowDisabledVersions,
      },
    }),
    [
      selectedCategory,
      setSelectedCategory,
      filteringText,
      setFilteringText,
      isShowDisabledVersions,
      setIsShowDisabledVersions,
    ]
  );

  return (
    <inOrganizationContext.Provider value={ctxValue}>
      {children}
    </inOrganizationContext.Provider>
  );
};

export const useInOrganizationContext = () => {
  return useContext(inOrganizationContext);
};
