import classNames from "classnames";
import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { postChangeAccountDefaultOrgId } from "../../../../api/accounts";
import { OrganizationsItemRaw } from "../../../../domain/Organization";
import { useAppAccount } from "../../../AppProvider/AppAccountProvider";
import { useAppNotificationManager } from "../../../AppProvider/AppNotificationProvider";
import { useAppOrgsAndInvitedOrgs } from "../../../AppProvider/AppOrgsAndInvitedOrgsProvider";

import styles from "./index.module.css";

import { useAppRouteParams } from "@/AppRoutes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";

export const OrganizationSwitchDialog: React.FC<{
  isVisibleDialog: boolean;
  closeDialog: () => void;
}> = ({ isVisibleDialog, closeDialog }) => {
  const navigate = useNavigate();

  const { account, setAccount, isAADeveloperOrAbove } = useAppAccount();
  const { organizationId } = useAppRouteParams();
  const { organizations, isLoadedOrganizations } = useAppOrgsAndInvitedOrgs();
  const { showErrorNotification } = useAppNotificationManager();

  const [isInProgress, setIsInProgress] = useState(false);

  const getIsDisableLink = useCallback(
    (targetOrganization: OrganizationsItemRaw) =>
      targetOrganization.org_id === organizationId || isInProgress,
    [organizationId, isInProgress]
  );

  const runChangeOrganization = useCallback(
    async (targetOrganization: OrganizationsItemRaw) => {
      // 対象組織が遷移可能か
      if (getIsDisableLink(targetOrganization)) return;
      try {
        setIsInProgress(true);
        const response = await postChangeAccountDefaultOrgId({
          uid: account?.uid ?? "",
          default_org_id: targetOrganization.org_id,
        });
        setAccount(response);
        setIsInProgress(false);
      } catch (error) {
        showErrorNotification("基本組織設定に失敗しました", error);
      }
      closeDialog();
      navigate(
        targetOrganization.org_id
          ? `/organizations/${targetOrganization.org_id}`
          : "/"
      );
    },
    [account, getIsDisableLink]
  );

  return (
    <Dialog
      open={isVisibleDialog}
      onOpenChange={() => {
        if (isVisibleDialog ?? isInProgress) return;
        closeDialog();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>組織を切り替える</DialogTitle>
          <DialogContent>
            {organizations.length > 0
              ? "現在ログインしている組織から別の組織に切り替えます。"
              : "所属している組織はありません"}
            {organizations.length > 0 ? (
              <>
                <div className={styles.container}>
                  {[...organizations]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((targetOrganization) => {
                      return (
                        <div
                          key={targetOrganization.org_id}
                          className={classNames(styles.organizationLink, {
                            [styles.organizationDisableLink]:
                              getIsDisableLink(targetOrganization),
                          })}
                          onMouseDown={async () => {
                            await runChangeOrganization(targetOrganization);
                          }}
                        >
                          {targetOrganization.name} (ID :{" "}
                          {targetOrganization.org_id} )
                        </div>
                      );
                    })}
                </div>
              </>
            ) : null}
          </DialogContent>
          <DialogActions>
            {isAADeveloperOrAbove && (
              <Button
                appearance="primary"
                disabled={isInProgress}
                onClick={() => {
                  closeDialog();
                  navigate("/organization_create");
                }}
              >
                組織を新規作成
              </Button>
            )}
            <Button
              disabled={isInProgress}
              onClick={() => {
                closeDialog();
              }}
            >
              キャンセル
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
