import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { IContextualMenuItem } from "@fluentui/react";
import React, { useMemo, useCallback, useState, useEffect } from "react";

import { useAppRouteId, useAppRouteParams } from "../../../../AppRoutes";
import {
  Sheet,
  SheetOptimizeTypesGroup,
  sheetOptimizeTypeGroupList,
  sheetOptimizeTypeMap,
  useSetSheet,
  useUpdateSheet,
} from "../../../../domain/Sheet";
import { ManagedTransaction } from "../../../../firebase/firestore";
import { useAppNotificationManager } from "../../../AppProvider/AppNotificationProvider";
import { useDialogContextMenuItemsSheet } from "../../../Dialog/DialogFirestore/DialogSheet/hooks";
import { SortableTmpMenuSet } from "../../helpers";

import styles from "./index.module.css";

import { LoadingCoverWithoutText } from "@/components/LoadingCover";
import { useContextMenuItemsSheetsMenu } from "@/contextItems/hooks";
import { useAuthorizedSheets } from "@/hooks/useAuthorizedCollections";
import { useVersionPermission } from "@/hooks/useCollectionPermission";
import { useReferencedSheets } from "@/hooks/useReferencedSheets";

/**
 * @description シート一覧を表示し、編集や新規作成の操作機能を提供
 */
export const SheetsMenu: React.FC = () => {
  const { versionId } = useAppRouteParams();
  const [hasVersionWritePermission, isLoadedPermission] =
    useVersionPermission("write");
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);
  const [referencedSheets, isLoadedReferencedSheets] = useReferencedSheets();

  const [isCloseSheets, setIsCloseSheets] = useState(false);

  /**
   * contextItems
   */
  const contextMenuItemsSheets: IContextualMenuItem[] =
    useContextMenuItemsSheetsMenu({});

  const { renderDialogsSheet, getContextMenuItemsSheet } =
    useDialogContextMenuItemsSheet();

  const sortedSheets = useMemo(
    () =>
      [...sheets].sort((a, b) => {
        return Math.sign(a.sort - b.sort);
      }),
    [sheets]
  );

  // 実際に描画されるシートカテゴリとシート配列のmap
  const displaySheetsMap = useMemo(() => {
    const result = new Map<SheetOptimizeTypesGroup, Sheet[]>();
    for (const sheetOptimizeTypeGroup of sheetOptimizeTypeGroupList) {
      const targetOptimizeTypes = sheetOptimizeTypeMap.get(
        sheetOptimizeTypeGroup
      );
      if (!targetOptimizeTypes) continue;
      result.set(
        sheetOptimizeTypeGroup,
        sortedSheets.filter((sheet) =>
          targetOptimizeTypes.includes(sheet.sheetOptimizeType)
        )
      );
    }
    return result;
  }, [sortedSheets]);

  if (!isLoadedSheets || !isLoadedPermission || !isLoadedReferencedSheets) {
    return <LoadingCoverWithoutText />;
  }
  return (
    <>
      <SortableTmpMenuSet
        id={"sheet"}
        text={"シート"}
        iconName={isCloseSheets ? "ChevronDown" : "ChevronUp"}
        onClick={() => {
          setIsCloseSheets(!isCloseSheets);
        }}
        contextMenuItems={contextMenuItemsSheets}
      />
      {!isCloseSheets &&
        Array.from(displaySheetsMap).map(([sheetOptimizeTypeGroup, sheets]) => {
          return (
            <SheetsSortableTmpMenuSet
              key={sheetOptimizeTypeGroup}
              sheetOptimizeTypeGroup={sheetOptimizeTypeGroup}
              sheets={sheets}
              referencedSheets={referencedSheets}
              getContextMenuItemsSheet={getContextMenuItemsSheet}
              disabled={!hasVersionWritePermission}
            />
          );
        })}
      {renderDialogsSheet(sortedSheets)}
    </>
  );
};

const SheetsSortableTmpMenuSet: React.FC<{
  sheetOptimizeTypeGroup: SheetOptimizeTypesGroup;
  sheets: Sheet[];
  referencedSheets: (Sheet & { refVersionId: string })[];
  getContextMenuItemsSheet: ReturnType<
    typeof useDialogContextMenuItemsSheet
  >["getContextMenuItemsSheet"];
  disabled?: boolean;
}> = ({
  sheetOptimizeTypeGroup,
  sheets,
  referencedSheets,
  getContextMenuItemsSheet,
  disabled,
}) => {
  const { organizationId, versionId, sheetId } = useAppRouteParams();
  const appRouteId = useAppRouteId();
  const updateSheet = useUpdateSheet();

  /**
   * dnd-kit
   */
  const [displaySheets, setDisplaySheets] = useState(sheets);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor)
  );
  const { showErrorNotification } = useAppNotificationManager();
  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      if (!over) return;
      if (active.id !== over.id) {
        const oldIndex = sheets.findIndex((item) => item.id === active.id);
        const newIndex = sheets.findIndex((item) => item.id === over.id);
        const newSheets = arrayMove(sheets, oldIndex, newIndex);
        const newSheetSorts = newSheets
          .map(({ sort }) => sort)
          .sort((a, b) => Math.sign(a - b));
        const newSheetsWithSort = newSheets.map((sheet, index) => ({
          ...sheet,
          sort: newSheetSorts[index],
        }));
        setDisplaySheets(newSheetsWithSort);
        try {
          await ManagedTransaction.runTransaction(async (transaction) => {
            // NOTE: 既存のドキュメント更新のため、updateDocでOK
            for (const sheet of newSheetsWithSort) {
              await updateSheet(
                {
                  ...sheet,
                },
                { versionId, sheetId: sheet.id },
                { transaction }
              );
            }
          });
        } catch (error) {
          setDisplaySheets(sheets);
          showErrorNotification("Sheetのソートに失敗しました。", error);
        }
      }
    },
    [sheets, updateSheet, versionId, showErrorNotification]
  );

  useEffect(() => {
    setDisplaySheets(sheets);
  }, [sheets]);

  return (
    <div key={sheetOptimizeTypeGroup}>
      <div className={styles.sheetOptimizeType}>{sheetOptimizeTypeGroup}</div>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={async (e) => {
            await handleDragEnd(e);
          }}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={displaySheets.map(({ id }) => id)}
            disabled={disabled}
          >
            {displaySheets.map((sheet) => {
              const referencedSheetVersionId = referencedSheets.find(
                (refSheet) =>
                  refSheet.id === sheet.referTo?.sheetId &&
                  refSheet.refVersionId === sheet.referTo?.versionId
              )?.refVersionId;
              return (
                <SortableTmpMenuSet
                  key={sheet.id}
                  id={sheet.id}
                  text={sheet.displayName}
                  referencedSheetVersionId={referencedSheetVersionId}
                  iconName={referencedSheetVersionId ? "ArrowUpRight" : ""}
                  to={`/organizations/${organizationId}/versions/${versionId}/sheets/${sheet.id}`}
                  active={appRouteId === "sheet" && sheetId === sheet.id}
                  contextMenuItems={getContextMenuItemsSheet(sheet)}
                  isSortable={!disabled}
                />
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};
