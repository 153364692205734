import { berthScheduleStrictViewType } from "@/viewType/viewTypes/Optium/cptDom/strictViewType";
import loadable from "@loadable/component";

const ViewRender = loadable(() => import("./render"));

export const viewTypeBerthScheduleView: berthScheduleStrictViewType = {
  key: "cptdomBerthScheduleView",
  displayName: "バースビュー",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "config",
      displayName: "設定",
      dependSheetFields: [
        {
          key: "main_oil_group",
          displayName: "main_oil_group",
          type: "enum",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "active_fixed_modes",
          displayName: "active_fixed_modes",
          type: "array",
        },
        {
          key: "default_fixed_mode",
          displayName: "default_fixed_mode",
          type: "enum",
        },
        {
          key: "additional_shown_berth_codes",
          displayName: "additional_shown_berth_codes",
          type: "array",
        },
        {
          key: "display_months_before_today",
          displayName: "display_months_before_today",
          type: "integer",
        },
        {
          key: "display_months_after_today",
          displayName: "display_months_after_today",
          type: "integer",
        },
      ],
    },
    {
      key: "shipMaster",
      displayName: "船マスタ",
      dependSheetFields: [
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "ship_name",
          displayName: "ship_name",
          type: "string",
        },
        {
          key: "ship_size",
          displayName: "ship_size",
          type: "string",
        },
        {
          key: "oil_group",
          displayName: "oil_group",
          type: "enum",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
        {
          key: "ship_type",
          displayName: "ship_type",
          type: "enum",
        },
        {
          key: "is_shown",
          displayName: "is_shown",
          type: "boolean",
        },
        {
          key: "is_optimization_target",
          displayName: "is_optimization_target",
          type: "boolean",
        },
      ],
    },
    {
      key: "shipSizeMaster",
      displayName: "船型マスタ",
      dependSheetFields: [
        {
          key: "ship_size",
          displayName: "ship_size",
          type: "string",
        },
        {
          key: "speed_kt",
          displayName: "speed_kt",
          type: "float",
        },
        {
          key: "consumption_heavy_a",
          displayName: "consumption_heavy_a",
          type: "float",
        },
        {
          key: "consumption_heavy_c",
          displayName: "consumption_heavy_c",
          type: "float",
        },
        {
          key: "dwt",
          displayName: "dwt",
          type: "integer",
        },
      ],
    },
    {
      key: "portMaster",
      displayName: "基地マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "port_name",
          displayName: "port_name",
          type: "string",
        },
        {
          key: "port_type",
          displayName: "port_type",
          type: "enum",
        },
        {
          key: "contract_type",
          displayName: "contract_type",
          type: "enum",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "oilMaster",
      displayName: "油種マスタ",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "oil_name",
          displayName: "oil_name",
          type: "string",
        },
        {
          key: "oil_group",
          displayName: "oil_group",
          type: "enum",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "berthMaster",
      displayName: "バースマスタ",
      dependSheetFields: [
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "berth_name",
          displayName: "berth_name",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "white_ship_size_min",
          displayName: "white_ship_size_min",
          type: "integer",
        },
        {
          key: "white_ship_size_max",
          displayName: "white_ship_size_max",
          type: "integer",
        },
        {
          key: "black_ship_size_min",
          displayName: "black_ship_size_min",
          type: "integer",
        },
        {
          key: "black_ship_size_max",
          displayName: "black_ship_size_max",
          type: "integer",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "coordinateMaster",
      displayName: "座標マスタ",
      dependSheetFields: [
        {
          key: "place",
          displayName: "place",
          type: "string",
        },
        {
          key: "lat",
          displayName: "lat",
          type: "float",
        },
        {
          key: "lon",
          displayName: "lon",
          type: "float",
        },
      ],
    },
    {
      key: "companyMaster",
      displayName: "会社マスタ",
      dependSheetFields: [
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
        {
          key: "company_name",
          displayName: "company_name",
          type: "string",
        },
      ],
    },
    {
      key: "berthOilAvailableMaster",
      displayName: "バース油種可否マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "is_loadable",
          displayName: "is_loadable",
          type: "boolean",
        },
        {
          key: "is_unloadable",
          displayName: "is_unloadable",
          type: "boolean",
        },
      ],
    },
    {
      key: "berthShipAvailableMaster",
      displayName: "バース船可否マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_loadable",
          displayName: "is_loadable",
          type: "boolean",
        },
        {
          key: "is_unloadable",
          displayName: "is_unloadable",
          type: "boolean",
        },
      ],
    },
    {
      key: "inactiveFlagMaster",
      displayName: "不稼動フラグマスタ",
      dependSheetFields: [
        {
          key: "main_cause",
          displayName: "main_cause",
          type: "string",
        },
        {
          key: "flag",
          displayName: "flag",
          type: "string",
        },
        {
          key: "sub_cause",
          displayName: "sub_cause",
          type: "string",
        },
      ],
    },
    {
      key: "nonLoadingFlagMaster",
      displayName: "不積フラグマスタ",
      dependSheetFields: [
        {
          key: "main_cause",
          displayName: "main_cause",
          type: "string",
        },
        {
          key: "flag",
          displayName: "flag",
          type: "string",
        },
        {
          key: "sub_cause",
          displayName: "sub_cause",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan",
      displayName: "転送計画",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan1",
      displayName: "転送計画_参照用_1",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan2",
      displayName: "転送計画_参照用_2",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan3",
      displayName: "転送計画_参照用_3",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan4",
      displayName: "転送計画_参照用_4",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan5",
      displayName: "転送計画_参照用_5",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "cargoHandling",
      displayName: "荷役",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargoHandling1",
      displayName: "荷役_参照用_1",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargoHandling2",
      displayName: "荷役_参照用_2",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargoHandling3",
      displayName: "荷役_参照用_3",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargoHandling4",
      displayName: "荷役_参照用_4",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargoHandling5",
      displayName: "荷役_参照用_5",
      dependSheetFields: [
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "string",
        },
        {
          key: "timeframe",
          displayName: "timeframe",
          type: "enum",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "earliest_visit_date",
          displayName: "earliest_visit_date",
          type: "string",
        },
        {
          key: "latest_visit_date",
          displayName: "latest_visit_date",
          type: "string",
        },
        {
          key: "is_port_fixed",
          displayName: "is_port_fixed",
          type: "boolean",
        },
      ],
    },
    {
      key: "cargo",
      displayName: "貨物",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "cargo1",
      displayName: "貨物_参照用_1",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "cargo2",
      displayName: "貨物_参照用_2",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "cargo3",
      displayName: "貨物_参照用_3",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "cargo4",
      displayName: "貨物_参照用_4",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "cargo5",
      displayName: "貨物_参照用_5",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oli_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "berthUnavailable",
      displayName: "バース利用不可",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "event_name",
          displayName: "event_name",
          type: "enum",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "fobVoyagePlan",
      displayName: "倉取タスク",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_name",
          displayName: "ship_name",
          type: "string",
        },
        {
          key: "ship_size",
          displayName: "ship_size",
          type: "string",
        },
        {
          key: "company_name",
          displayName: "company_name",
          type: "string",
        },
        {
          key: "is_overlapping_prohibited",
          displayName: "is_overlapping_prohibited",
          type: "boolean",
        },
        {
          key: "loading_date",
          displayName: "loading_date",
          type: "string",
        },
        {
          key: "loading_timeframe",
          displayName: "loading_timeframe",
          type: "enum",
        },
        {
          key: "loading_port_code",
          displayName: "loading_port_code",
          type: "string",
        },
        {
          key: "loading_berth_code",
          displayName: "loading_berth_code",
          type: "string",
        },
        {
          key: "unloading_date",
          displayName: "unloading_date",
          type: "string",
        },
        {
          key: "unloading_port_name",
          displayName: "unloading_port_name",
          type: "string",
        },
        {
          key: "cargo_handling_start_time",
          displayName: "cargo_handling_start_time",
          type: "date-time",
        },
        {
          key: "cargo_handling_end_time",
          displayName: "cargo_handling_end_time",
          type: "date-time",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "fobCargoInput",
      displayName: "倉取荷役",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
      ],
    },
    {
      key: "flushing",
      displayName: "フラッシング",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "flushing1",
      displayName: "フラッシング1",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "flushing2",
      displayName: "フラッシング2",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "flushing3",
      displayName: "フラッシング3",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "flushing4",
      displayName: "フラッシング4",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "flushing5",
      displayName: "フラッシング5",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "start_date",
          displayName: "start_date",
          type: "string",
        },
        {
          key: "start_timeframe",
          displayName: "start_timeframe",
          type: "enum",
        },
        {
          key: "end_date",
          displayName: "end_date",
          type: "string",
        },
        {
          key: "end_timeframe",
          displayName: "end_timeframe",
          type: "enum",
        },
        {
          key: "start_berth_code",
          displayName: "start_berth_code",
          type: "string",
        },
        {
          key: "end_berth_code",
          displayName: "end_berth_code",
          type: "string",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
      ],
    },
    {
      key: "otherShipReceivingInput",
      displayName: "他社船入荷",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_name",
          displayName: "ship_name",
          type: "string",
        },
        {
          key: "ship_size",
          displayName: "ship_size",
          type: "string",
        },
        {
          key: "is_overlapping_prohibited",
          displayName: "is_overlapping_prohibited",
          type: "boolean",
        },
        {
          key: "loading_port_name",
          displayName: "loading_port_name",
          type: "string",
        },
        {
          key: "loading_date",
          displayName: "loading_date",
          type: "string",
        },
        {
          key: "unloading_port_code",
          displayName: "unloading_port_code",
          type: "string",
        },
        {
          key: "unloading_berth_code",
          displayName: "unloading_berth_code",
          type: "string",
        },
        {
          key: "unloading_date",
          displayName: "unloading_date",
          type: "string",
        },
        {
          key: "unloading_timeframe",
          displayName: "unloading_timeframe",
          type: "enum",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
        {
          key: "cargos",
          displayName: "cargos",
          type: "string",
        },
      ],
    },
  ],
  configSchemas: [],
};
