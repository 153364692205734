import React, {
  useMemo,
  ReactNode,
  useCallback,
  createContext,
  useContext,
  useState,
} from "react";

import { useAppRouteParams } from "../../../AppRoutes";
import { MemberRaw } from "../../../domain/Member";
import { User } from "../../../domain/User";
import { ViewTypeSessionData } from "../../../domain/ViewType";
import { useOrganizationSession } from "../../InOrganizationProvider/SessionProvider";
import { getViewSessionKey } from "../../InOrganizationProvider/SessionProvider/helpers";
import {
  useAccessUsersInVersion,
  useOtherSessionsData,
} from "../../InOrganizationProvider/SessionProvider/hooks";

import { useAppAccount } from "@/components/AppProvider/AppAccountProvider";
import { AccountRaw } from "@/domain/Account";
import { useVersionPermission } from "@/hooks/useCollectionPermission";

const ctx = createContext<{
  viewSessionData: ViewTypeSessionData;
  updateViewSessionData: (viewSessionData: ViewTypeSessionData) => void;
  otherSessionsData: {
    id: string;
    data: ViewTypeSessionData;
    user: User;
  }[];
  accessMembersRaw: MemberRaw[];
  account: AccountRaw | null;
  isDisableOperateView: boolean;
}>({
  viewSessionData: {
    selectingSheetId: null,
    selectingRowIds: [],
    selectingFieldId: null,
  },
  updateViewSessionData: () => {
    //nop
  },
  otherSessionsData: [],
  accessMembersRaw: [],
  account: null,
  isDisableOperateView: true,
});

/**
 * @description view配下で利用する、sessionData、sheet操作可能権限、アカウント情報の管理
 */
export const ViewContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { viewId, versionId } = useAppRouteParams();
  const [hasVersionWritePermission] = useVersionPermission("write");
  const { sessions, sessionId } = useOrganizationSession();

  // NOTE: 現在ログインを行っているアカウントの情報
  const { account } = useAppAccount();

  // NOTE: 組織内、同じバージョン内で、同じsheet,viewにアクセスしているメンバ一覧（自身を含む）
  const accessMembersRaw = useAccessUsersInVersion({
    prefix: "views__",
    targetDataId: viewId,
    targetVersionId: versionId,
  });

  // NOTE: ビュー経由で、ユーザの選択しているシート、行、フィールドのリアルタイムな情報を管理する
  // ここのデータ自体は、Firebase Realtime Databaseに対するCRUDになっている
  const [viewSessionData, setViewSessionData] = useState<ViewTypeSessionData>({
    selectingSheetId: null,
    selectingRowIds: [],
    selectingFieldId: null,
  });
  const updateViewSessionData = useCallback(
    (viewSessionData: ViewTypeSessionData) => {
      setViewSessionData(viewSessionData);
    },
    [setViewSessionData]
  );

  const sessionKey = getViewSessionKey(viewId);

  // NOTE: 同じ組織、同じversion内で、指定したsheet,viewにアクセスしているユーザのセッション情報の一覧（自身を除く）
  const otherSessionsData = useOtherSessionsData<ViewTypeSessionData>(
    sessions,
    sessionKey,
    sessionId,
    versionId
  );

  const value = useMemo(
    () => ({
      viewSessionData,
      updateViewSessionData,
      otherSessionsData,
      accessMembersRaw,
      account,
      isDisableOperateView: !hasVersionWritePermission,
    }),
    [
      viewSessionData,
      updateViewSessionData,
      otherSessionsData,
      accessMembersRaw,
      account,
      hasVersionWritePermission,
    ]
  );

  return <ctx.Provider value={value}>{children}</ctx.Provider>;
};

export const useViewContext = () => {
  return useContext(ctx);
};
