import {
  FrontendAddonHeaderRawForm,
  FrontendAddonRaw,
  FrontendAddonRawForm,
  FrontendAddonTargetVersionRaw,
  FrontendAddonVersionRaw,
  FrontendAddonWithTargetVersionRaw,
  FrontendAddonWithVersionInfo,
  FrontendAddonWithVersionInfoRaw,
  parseFrontendAddonWithVersionInfoRaw,
} from "@/domain/FrontendAddon";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrl = "/frontend_addons";
const apiUrlAdvanced = apiUrl + "/advanced";

// 組織で許可されているかつ有効なFrontendAddonをリストで取得する
export const getPermittedFrontendAddons = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<FrontendAddonWithVersionInfo[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    items: FrontendAddonWithVersionInfoRaw[];
  }>(apiUrl, {
    ...apiOrganizationIdParams,
  });
  return response.data.items.map((raw) =>
    parseFrontendAddonWithVersionInfoRaw(raw)
  );
};

// 組織が所有するFrontendAddonをリストで取得する
export const getFrontendAddons = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<FrontendAddonWithTargetVersionRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    items: FrontendAddonWithTargetVersionRaw[];
  }>(apiUrlAdvanced, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};

export const getFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonWithTargetVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    item: FrontendAddonWithTargetVersionRaw;
  }>(`${apiUrlAdvanced}/${frontendAddonId}`, {
    ...apiOrganizationIdParams,
  });
  return response.data.item;
};

export const postCreateFrontendAddon = async ({
  organizationId,
  FrontendAddonRawForm,
  file,
}: {
  organizationId: string;
  FrontendAddonRawForm: FrontendAddonRawForm;
  file: File;
}): Promise<FrontendAddonRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  if (FrontendAddonRawForm.description) {
    form.append("description", FrontendAddonRawForm.description);
  }
  form.append("privacy_setting", FrontendAddonRawForm.privacy_setting);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlAdvanced}`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postEditFrontendAddonHeader = async ({
  organizationId,
  FrontendAddonHeaderRawForm,
  frontendAddonId,
}: {
  organizationId: string;
  FrontendAddonHeaderRawForm: FrontendAddonHeaderRawForm;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlAdvanced}/${frontendAddonId}`,
    FrontendAddonHeaderRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postDisableFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlAdvanced}/${frontendAddonId}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const postEnableFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlAdvanced}/${frontendAddonId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const postRemoveFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post(
    `${apiUrlAdvanced}/${frontendAddonId}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const getFrontendAddonVersions = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonVersionRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: FrontendAddonVersionRaw[] }>(
    `${apiUrlAdvanced}/${frontendAddonId}/frontend_versions`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};

export const postCreateFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  file,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  file: File;
}): Promise<FrontendAddonVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  const response = await API_CLIENT.post<{ item: FrontendAddonVersionRaw }>(
    `${apiUrlAdvanced}/${frontendAddonId}/frontend_versions`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postEditFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  FrontendAddonVersionId,
  file,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  FrontendAddonVersionId: FrontendAddonVersionRaw["id"];
  file: File;
}): Promise<FrontendAddonVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  const response = await API_CLIENT.post<{ item: FrontendAddonVersionRaw }>(
    `${apiUrlAdvanced}/${frontendAddonId}/frontend_versions/${FrontendAddonVersionId}`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postSetFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  targetVersion,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  targetVersion: FrontendAddonTargetVersionRaw;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post(
    `${apiUrlAdvanced}/${frontendAddonId}/frontend_masters`,
    targetVersion,
    {
      ...apiOrganizationIdParams,
    }
  );
};
