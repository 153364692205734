import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const appSideNavGlobalContext = createContext<{
  categoriesMap: Map<string, { isClose: boolean; isShowAll: boolean }>;
  toggleIsCloseCategoryVersions: (name: string) => void;
  toggleShowAllCategoryVersions: (name: string) => void;
}>({} as never);

export const AppSideNavGlobalContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { versionCategories } = useVersionCategories();
  const map = new Map<string, { isClose: boolean; isShowAll: boolean }>();
  map.set("unknown", { isClose: false, isShowAll: false });
  if (versionCategories) {
    versionCategories.forEach((category) => {
      map.set(category.id, { isClose: false, isShowAll: false });
    });
  }

  const [categoriesMap, setCategoriesMap] = useState(map);
  const toggleIsCloseCategoryVersions = useCallback(
    (versionCategoryId: string) =>
      setCategoriesMap((prev) => {
        const current = prev.get(versionCategoryId);
        if (!current) return prev;
        const newMap = new Map(prev);
        newMap.set(versionCategoryId, {
          ...current,
          isClose: !current.isClose,
        });
        return newMap;
      }),
    []
  );
  const toggleShowAllCategoryVersions = useCallback(
    (versionCategoryId: string) =>
      setCategoriesMap((prev) => {
        const current = prev.get(versionCategoryId);
        if (!current) return prev;
        const newMap = new Map(prev);
        newMap.set(versionCategoryId, {
          ...current,
          isShowAll: !current.isShowAll,
        });
        return newMap;
      }),
    []
  );

  const ctx = useMemo(
    () => ({
      categoriesMap,
      toggleIsCloseCategoryVersions,
      toggleShowAllCategoryVersions,
    }),
    [
      categoriesMap,
      toggleIsCloseCategoryVersions,
      toggleShowAllCategoryVersions,
    ]
  );

  return (
    <appSideNavGlobalContext.Provider value={ctx}>
      {children}
    </appSideNavGlobalContext.Provider>
  );
};

export const useAppSideNavGlobalContext = () => {
  return useContext(appSideNavGlobalContext);
};
