import { useAppRouteParams } from "@/AppRoutes";
import { getAdminAppConnectMasters } from "@/api/admin/appConnectMaster";
import {
  getAdminAppViewMaster,
  getAdminAppViewMasters,
} from "@/api/admin/appViewMaster";
import { getAdminConnectMasters } from "@/api/admin/connectMasters";
import { getAdminInvitedMembers, getAdminMembers } from "@/api/admin/member";
import { getAdminOrganization } from "@/api/admin/organization";
import { getAdminRoles } from "@/api/admin/role";
import { getAdminVersionCategories } from "@/api/admin/versionCategory";
import { getAdminViewMasters } from "@/api/admin/viewMasters";
import { getAPIErrorDetailAndMessage } from "@/api/helpers";
import { AppConnectMasterRaw } from "@/domain/AppConnectMasters";
import { AppViewMasterRaw } from "@/domain/AppViewMasters";
import { ConnectMasterRaw } from "@/domain/ConnectMaster";
import { MemberRaw } from "@/domain/Member";
import { OrganizationRaw } from "@/domain/Organization";
import { VersionCategoryRaw } from "@/domain/VersionCategories";
import { ViewMasterRaw } from "@/domain/ViewMaster";
import { Role, RoleRaw } from "@/domain/roles";
import { parsePermissions } from "@/helpers/permissions";
import { parseDateTimeOrISODateTime } from "@/utils/dateFnsHelper";
import { compareDesc } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";

/** adminプロバイダで扱う型を定義
 * /admin/accountsおよび/admin/organizationsは
 * ページネーションが必要なため個別プロバイダ内で取得する
 */
type AdminAPIReturnType =
  | AppViewMasterRaw[]
  | AppViewMasterRaw
  | AppConnectMasterRaw[]
  | OrganizationRaw
  | { members: MemberRaw[]; invitedMembers: MemberRaw[] }
  | VersionCategoryRaw[]
  | Role[]
  | ViewMasterRaw[]
  | ConnectMasterRaw[];

/**
 * adminAPIのエンドポイントのマッピング
 * appViewMasters: ビューマスタ一覧
 * appViewMaster: ビューマスタ詳細
 * appConnectMasters: Connectマスタ一覧
 * organization: 組織詳細
 * members: 組織詳細 > メンバー一覧
 * version_categories: 組織詳細 > カテゴリ一覧
 * roles: 組織詳細 > ロール一覧
 * viewMasters: 組織詳細 > ビューマスタ一覧
 * connectMasters: 組織詳細 > Connectマスタ一覧
 */
const adminAPIEndpoints = [
  "organizations",
  "appViewMasters",
  "appViewMaster",
  "appConnectMasters",
  "organization",
  "members",
  "version_categories",
  "roles",
  "viewMasters",
  "connectMasters",
] as const;

export const useAdminAPI = <T extends AdminAPIReturnType>(
  endpoint: (typeof adminAPIEndpoints)[number]
) => {
  const { organizationId, viewMasterId } = useAppRouteParams();
  const [state, setState] = useState<T | null>(null);
  const [isLoadedState, setIsLoadedState] = useState<boolean>(false);
  const [_, setError] = useState<unknown>(null);

  const runReloadState = useCallback(async () => {
    try {
      setIsLoadedState(false);
      switch (endpoint) {
        case "appViewMasters": {
          const response = await getAdminAppViewMasters();
          setState(response as T);
          break;
        }
        case "appViewMaster": {
          if (!viewMasterId) break;
          const response = await getAdminAppViewMaster(viewMasterId);
          setState(response as T);
          break;
        }
        case "appConnectMasters": {
          const response = await getAdminAppConnectMasters();
          response.sort((a, b) => {
            const _a = parseDateTimeOrISODateTime(a.created_at);
            const _b = parseDateTimeOrISODateTime(b.created_at);
            return _a && _b ? compareDesc(_a, _b) : 0;
          });
          setState(response as T);
          break;
        }
        case "organization": {
          if (!organizationId) break;
          const response = await getAdminOrganization({
            organizationId,
          });
          setState(response as T);
          break;
        }
        case "members": {
          if (!organizationId) break;
          const membersResponse = await getAdminMembers({ organizationId });
          const invitedMembersResponse = await getAdminInvitedMembers({
            organizationId,
          });
          setState({
            members: membersResponse,
            invitedMembers: invitedMembersResponse,
          } as T);
          break;
        }
        case "version_categories": {
          if (!organizationId) break;
          const response = await getAdminVersionCategories({ organizationId });
          setState(response.sort((a, b) => Math.sign(a.sort - b.sort)) as T);
          break;
        }
        case "roles": {
          if (!organizationId) break;
          const response = await getAdminRoles({ organizationId });
          response
            .filter((role) => !role.is_disabled)
            .sort((a, b) => {
              const _a = parseDateTimeOrISODateTime(a.created_at);
              const _b = parseDateTimeOrISODateTime(b.created_at);
              return _a && _b ? compareDesc(_a, _b) : 0;
            });
          const parsedRole: Role[] = response.map((role) => ({
            ...role,
            permissions: parsePermissions(role.permissions),
          }));
          setState(parsedRole as T);
          break;
        }
        case "viewMasters": {
          if (!organizationId) break;
          const response = await getAdminViewMasters({
            organizationId,
          });
          response.sort((a, b) => {
            const _a = parseDateTimeOrISODateTime(a.created_at);
            const _b = parseDateTimeOrISODateTime(b.created_at);
            return _a && _b ? compareDesc(_a, _b) : 0;
          });
          setState(response as T);
          break;
        }
        case "connectMasters": {
          if (!organizationId) break;
          const response = await getAdminConnectMasters({
            organizationId,
          });
          response.sort((a, b) => {
            const _a = parseDateTimeOrISODateTime(a.created_at);
            const _b = parseDateTimeOrISODateTime(b.created_at);
            return _a && _b ? compareDesc(_a, _b) : 0;
          });
          setState(response as T);
          break;
        }
      }
    } catch (err) {
      setError(() => {
        if (typeof err == "object") {
          const e = {
            ...err,
            message: getAPIErrorDetailAndMessage(err),
          } as Error;
          throw e;
        } else {
          throw new Error("不明なエラー");
        }
      });
    } finally {
      setIsLoadedState(true);
    }
  }, [organizationId, viewMasterId]);

  useEffect(() => {
    runReloadState();
  }, [runReloadState]);

  const stateCtxValue = useMemo(
    () => ({
      state,
      runReloadState,
      isLoadedState,
    }),
    [state, runReloadState, isLoadedState]
  );

  return stateCtxValue;
};
