import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import {
  TypeSchema,
  TypeSchemaRaw,
  encodeTypeSchema,
  parseTypeSchema,
} from "./TypeSchema";

/**
 * configSchemasの型定義
 *
 * key: Firestoreで扱うデータのキー名
 * displayName: Config設定ボックスに表示される名称
 * type: 対象データの型
 * typeParameters: 年月日表示など、定義型を表示する際に利用するパラメータ
 * typeFields: 入れ子にするTypeSchema。配列やオブジェクトで利用
 */
export type ViewConfigSchema = {
  id: string;
  displayName: string;
  key: string;
  value: string;
} & TypeSchema;

type ViewConfigSchemaRaw = {
  id: string;
  display_name: string;
  key: string;
  value: string;
} & TypeSchemaRaw;

const processorOptions: FirestoreCollectionProsessorOptions<
  ViewConfigSchema,
  ViewConfigSchemaRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, viewId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/views/${viewId}/view_config_field_schemas`,
  toFirestore: ({
    id,
    displayName,
    value,
    key,
    type,
    typeFields,
    typeParameters,
  }) => ({
    id,
    display_name: displayName,
    value,
    key,
    ...encodeTypeSchema({ type, typeFields, typeParameters }),
  }),
  fromFirestore: ({
    id,
    display_name,
    value,
    key,
    field_type,
    field_type_fields,
    field_type_parameters,
    // type,
    // typeFields,
    // typeParameters,
  }) => ({
    id,
    displayName: display_name,
    value,
    key,
    ...parseTypeSchema({
      field_type,
      field_type_fields,
      field_type_parameters,
      // type,
      // typeFields,
      // typeParameters,
    }),
  }),
};

export const {
  //useItem: useSheetDataRow,
  useGetAll: useGetViewConfigSchemas,
  useSet: useSetViewConfigSchema,
  useUpdate: useUpdateViewConfigSchema,
  useDelete: useDeleteViewConfigSchema,
  useItems: useViewConfigSchemas,
} = createFirestoreCollectionProsessor(processorOptions);
