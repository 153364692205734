import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import React, { useCallback, useState } from "react";
import {
  Version,
  useSetVersion,
  useUpdateVersion,
} from "../../../../../domain/Version";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

export const DialogVersionUpdateCategorySome: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
    newCategoryId: string | null;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  newCategoryId,
  dialogPhase,
  setDialogPhase,
}) => {
  const updateVersion = useUpdateVersion();

  const selectedVersions = versions.filter(({ id }) =>
    selectedVersionIds.includes(id)
  );

  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const progressTotal = selectedVersionIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const { versionCategories } = useVersionCategories();
  const selectedVersionCategoryName = versionCategories?.find(
    (category) => category.id === newCategoryId
  )?.name;

  const runUpdate = useCallback(async () => {
    if (selectedVersions.length > 0) {
      setProgressCount(0);
      setDialogPhase("in_progress");
      try {
        await ManagedTransaction.runTransaction(async (transaction) => {
          for (const version of selectedVersions) {
            await updateVersion(
              {
                ...version,
                category: newCategoryId ?? "",
              },
              {},
              { transaction }
            );
            setProgressCount((cnt) => cnt + 1);
          }
        });
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  }, [newCategoryId, selectedVersions, updateVersion, showErrorAPIDialog]);
  const messages = getDialogMessages({
    isSome: true,
    actionType: "updateCategory",
    targetType: "version",
  });

  if (!dialogPhase) {
    return null;
  }
  return (
    <>
      {renderDialogs({
        confirm: {
          ...messages.confirm,
          onClick: runUpdate,
          displayMessage: newCategoryId
            ? `選択バージョンのカテゴリを[${selectedVersionCategoryName}]に更新してもよろしいですか？`
            : "選択バージョンのカテゴリを未分類に更新してもよろしいですか？",
          onClickCancel: () => {
            setSelectedVersionIds([]);
          },
        },
        inProgress: {
          ...messages.inProgress,
          title: `選択バージョンのカテゴリを更新中...`,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};
