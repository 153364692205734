import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import "./ViewDependSheetField";
import "./ViewDependSheet";
import "./ViewConfigSchema";

export type View = {
  id: string;
  viewType: string;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  isDisabled: boolean;
  sort: number;
};
type ViewRaw = {
  id: string;
  viewType: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  is_disabled: boolean;
  sort: number;
};

export const checkViewHasBlankId = (v: View) => {
  return v.id === "";
};

const processorOptions: FirestoreCollectionProsessorOptions<
  View,
  ViewRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/views`,
  toFirestore: ({
    id,
    title,
    viewType,
    description,
    createdAt,
    updatedAt,
    isDisabled,
    sort,
  }) => ({
    id,
    title,
    viewType,
    description,
    created_at: createdAt,
    updated_at: updatedAt,
    is_disabled: isDisabled,
    sort,
  }),
  fromFirestore: ({
    id,
    title,
    viewType,
    description,
    created_at,
    updated_at,
    is_disabled,
    sort,
  }) => ({
    id,
    title,
    viewType,
    description,
    createdAt: created_at,
    updatedAt: updated_at,
    isDisabled: is_disabled,
    sort,
  }),
};

export const {
  useGetAll: useGetViews,
  useGet: useGetView,
  useSet: useSetView,
  useRecursiveDelete: useRecursiveDeleteView,
  useItem: useView,
  useItems: useViews,
} = createFirestoreCollectionProsessor(processorOptions);
