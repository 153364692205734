import { useEffect, useMemo } from "react";

import { usePermissions } from "@/components/InOrganizationProvider/PermissionsProvider";
import { useVersions } from "@/components/InOrganizationProvider/VersionsProvider";
import {
  Sheet,
  useGetSheet,
  useGetSheets,
  useSheetsQuery,
} from "@/domain/Sheet";
import { ComplexFirestoreQuerySetting } from "@/firebase/firestore";

// 対象のバージョン内の、自身のロールで許可されたシートのsnapshotのみを取得する（useSheetsを置き換える）
export const useAuthorizedSheets = (
  versionId: string
): [
  Sheet[],
  boolean,
  ComplexFirestoreQuerySetting<string> | null,
  (querySettingParam: ComplexFirestoreQuerySetting<string> | null) => void,
] => {
  const { versions, isLoadedVersions } = useVersions();
  const version = versions.find((v) => v.id === versionId);
  const { me, isLoadedMe } = usePermissions();
  const { permissions } = me || {};
  const authorizedSheetIds = permissions?.sheet.read;
  const authorizedVersionSheetIds = permissions?.version_sheet.read;

  // authorizedVersionsに対象バージョンが含まれる場合はread権限を持っているとみなす
  const query: ComplexFirestoreQuerySetting | null = version
    ? null
    : {
        logicalOp: "or",
        ops:
          authorizedSheetIds?.length || authorizedVersionSheetIds?.length
            ? [
                ...(authorizedSheetIds?.map((id) => ({
                  fieldPath: "__name__",
                  opStr: "==" as const,
                  value: id,
                })) ?? []),
                ...(authorizedVersionSheetIds
                  ?.filter(({ versionId: vId }) => vId === versionId)
                  .map(({ sheetId }) => ({
                    fieldPath: "__name__",
                    opStr: "==" as const,
                    value: sheetId,
                  })) ?? []),
              ]
            : [
                // 何ともマッチしないクエリを発行
                {
                  fieldPath: "id",
                  opStr: "==",
                  value: null,
                },
              ],
      };

  const [sheets, isLoadedSheets, querySetting, changeQuerySetting] =
    useSheetsQuery(
      { versionId },
      query === null ? undefined : async () => query
    );

  useEffect(() => {
    changeQuerySetting(query);
  }, [versions, permissions]);

  return [
    sheets,
    isLoadedMe && isLoadedVersions && isLoadedSheets,
    querySetting,
    changeQuerySetting,
  ];
};

// 自身のロールで許可されたシートのドキュメントのみを非同期に取得する（useGetSheetsを置き換える）
export const useGetAuthorizedSheets = () => {
  const { versions } = useVersions();
  const { me } = usePermissions();
  const { permissions } = me || {};
  const getSheet = useGetSheet();
  const getSheets = useGetSheets();
  return useMemo(
    () => async (versionId: string) => {
      // バージョンに対して権限を持つ場合は全シートを取得
      if (versions.some((v) => v.id === versionId)) {
        return await getSheets({ versionId });
      }
      const authorizedSheetIds = permissions?.sheet.read;
      const authorizedVersionIds = permissions?.version_sheet.read;
      const targetSheetIds = [
        ...(authorizedSheetIds ?? []),
        ...(authorizedVersionIds
          ?.filter(({ versionId: vId }) => vId === versionId)
          .map(({ sheetId }) => sheetId) ?? []),
      ];
      const authorizedSheets = await Promise.all(
        targetSheetIds.map(async (id) => await getSheet(id, { versionId }))
      );
      const filteredSheets: Sheet[] = authorizedSheets.filter(
        (sheet): sheet is Sheet => sheet !== null
      );
      return filteredSheets;
    },
    [versions, permissions, getSheet, getSheets]
  );
};

// 自身のロールで許可されたシートの単体ドキュメントのみを非同期に取得する（useGetSheetを置き換える）
export const useGetAuthorizedSheet = () => {
  const { versions } = useVersions();
  const { me } = usePermissions();
  const { permissions } = me || {};
  const getSheet = useGetSheet();
  return useMemo(
    () =>
      async ({
        versionId,
        sheetId,
      }: {
        versionId: string;
        sheetId: string;
      }) => {
        // バージョンに対して権限を持つ場合は全シートを取得
        if (versions.some((v) => v.id === versionId)) {
          return await getSheet(sheetId, { versionId });
        }
        const authorizedSheetIds = permissions?.sheet.read;
        const authorizedVersionIds = permissions?.version_sheet.read;
        const targetSheetIds = [
          ...(authorizedSheetIds ?? []),
          ...(authorizedVersionIds
            ?.filter(({ versionId: vId }) => vId === versionId)
            .map(({ sheetId }) => sheetId) ?? []),
        ];
        if (targetSheetIds.includes(sheetId)) {
          return await getSheet(sheetId, { versionId });
        }
        // パーミッションがない場合はnullを返す
        return null;
      },
    [versions, permissions, getSheet]
  );
};
