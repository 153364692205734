import { useAppRouteParams } from "@/AppRoutes";
import { getAPIErrorDetailOrMesssage } from "@/api/helpers";
import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import React, { useState, useEffect } from "react";
import { Version } from "../../../../../domain/Version";
import {
  DragAndDropFields,
  DragAndDropUploadData,
  dragAndDropAcceptFiles,
} from "../../../../DragAndDropFields";
import { DialogPhaseStatesType } from "../../../helpers/hooks";
import { useDialog } from "../../../helpers/hooks";
import { StatusList, VersionUpdateStatus } from "./StatusList";
import styles from "./index.module.css";

type Props = {
  versions: Version[];
  selectedVersionIds: string[];
  setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  targetResourceName: string;
  updateFunction: ({
    versionId,
    organizationId,
    file,
  }: {
    versionId: string;
    organizationId: string;
    file: File;
  }) => Promise<void>;
} & DialogPhaseStatesType;

export const DialogBulkUpdate: React.FC<Props> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  targetResourceName,
  updateFunction,
  dialogPhase,
  setDialogPhase,
}) => {
  const { organizationId } = useAppRouteParams();
  const { versionCategories } = useVersionCategories();
  const [file, setFile] = useState<File | null>(null);
  const [updateStatuses, setUpdateStatuses] = useState<VersionUpdateStatus[]>(
    []
  );
  const { renderDialogs } = useDialog({
    dialogPhase,
    setDialogPhase,
  });
  const [uploadedFileList, setUploadedFileList] = useState<
    DragAndDropUploadData[]
  >([]);

  useEffect(() => {
    setFile(uploadedFileList[0]?.file || null);
  }, [uploadedFileList]);

  const handleUpdate = async () => {
    if (!file) return;

    setUpdateStatuses(
      selectedVersionIds.map((id) => ({
        versionId: id,
        status: "waiting",
      }))
    );

    for (const versionId of selectedVersionIds) {
      try {
        setUpdateStatuses((prev) =>
          prev.map((s) =>
            s.versionId === versionId ? { ...s, status: "processing" } : s
          )
        );

        await updateFunction({ versionId, organizationId, file });

      // APIの実行時の制限に従い、1秒待機
      // ref: レートリミット: どの1秒間(ウィンドウ)を見ても、ある呼び出しユーザによるAPI実行は最大で1つしか存在しないこと。
      // そのため、APIの実行時に1秒待機することで、レートリミットを回避する。
        await new Promise((resolve) => setTimeout(resolve, 1000));

        setUpdateStatuses((prev) =>
          prev.map((s) =>
            s.versionId === versionId ? { ...s, status: "success" } : s
          )
        );
      } catch (error) {
        const { errorMessage } = getAPIErrorDetailOrMesssage(error);
        setUpdateStatuses((prev) =>
          prev.map((s) =>
            s.versionId === versionId
              ? {
                  ...s,
                  status: "failed",
                  error: errorMessage,
                }
              : s
          )
        );
      }
    }
  };

  return renderDialogs({
    confirm: {
      title: `${targetResourceName}更新`,
      buttonText: "一括更新",
      onClickCancel: () => {
        setDialogPhase(null);
        setFile(null);
        setUploadedFileList([]);
        setUpdateStatuses([]);
        setSelectedVersionIds([]);
      },
      onClick: handleUpdate,
      isDisableButton:
        !file || updateStatuses.some((s) => s.status !== "waiting"),
      element: (
        <div className={styles.dialogContent}>
          <div className={styles.dialogDescription}>
            <p>
              選択された {selectedVersionIds.length} 件のバージョンに対して
              {targetResourceName}を一括更新します。
            </p>
            <p>更新用のYAMLファイルを選択してください。</p>
          </div>
          <DragAndDropFields
            accept={dragAndDropAcceptFiles.yaml}
            isDisableCancelButton={false}
            uploadedFileList={uploadedFileList}
            setUploadedFileList={setUploadedFileList}
            maxFileNumber={1}
            fileTypes={["yaml"]}
          />
          <StatusList
            versions={versions}
            versionCategories={versionCategories ?? []}
            selectedVersionIds={selectedVersionIds}
            updateStatuses={updateStatuses}
          />
        </div>
      ),
    },
  });
};
