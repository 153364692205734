import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import {
  TypeSchema,
  TypeSchemaRaw,
  encodeTypeSchema,
  parseTypeSchema,
} from "./TypeSchema";

export type SheetFieldSchema = {
  id: string;
  displayName: string;
  name: string;
  isRequired: boolean;
  isUnique: boolean;
  isDisabled: boolean;
  sort: number;
  description: string | null;
} & TypeSchema;

type SheetFieldSchemaRaw = {
  id: string;
  display_name: string;
  name: string;
  is_required: boolean;
  is_unique: boolean;
  is_disabled: boolean;
  sort: number;
  description: string | null;
} & TypeSchemaRaw;

const processorOptions: FirestoreCollectionProsessorOptions<
  SheetFieldSchema,
  SheetFieldSchemaRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, sheetId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/sheets/${sheetId}/sheet_field_schemas`,
  toFirestore: ({
    id,
    displayName,
    name,
    isRequired,
    isUnique,
    isDisabled,
    type,
    typeFields,
    typeParameters,
    sort,
    description,
  }) => ({
    id,
    display_name: displayName,
    name,
    is_required: isRequired,
    is_unique: isUnique,
    is_disabled: isDisabled,
    sort,
    description,
    ...encodeTypeSchema({ type, typeFields, typeParameters }),
  }),
  fromFirestore: ({
    id,
    display_name,
    name,
    is_required,
    is_unique,
    is_disabled,
    sort,
    field_type,
    field_type_fields,
    field_type_parameters,
    description,
    // type,
    // typeFields,
    // typeParameters,
  }) => ({
    id,
    displayName: display_name,
    name,
    isRequired: is_required,
    isUnique: is_unique,
    isDisabled: is_disabled,
    sort,
    description,
    ...parseTypeSchema({
      field_type,
      field_type_fields,
      field_type_parameters,
      // type,
      // typeFields,
      // typeParameters,
    }),
  }),
};

export const {
  useGetAll: useGetSheetFieldSchemas,
  useSet: useSetSheetFieldSchema,
  useUpdate: useUpdateSheetFieldSchema,
  useDelete: useDeleteSheetFieldSchema,
  useItems: useSheetFieldSchemas,
  useSomeItems: useSomeSheetFieldSchemas,
} = createFirestoreCollectionProsessor(processorOptions);
