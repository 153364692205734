import React, { useEffect, useState } from "react";

import {
  Version,
  checkIsPublishedByFileUpload,
} from "../../../../../domain/Version";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { Input } from "@fluentui/react-components";

import { useAppRouteParams } from "../../../../../AppRoutes";
import { postDuplicateVersion } from "../../../../../api/version";
import styles from "./index.module.css";

export const DialogVersionDuplicate: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const versionId = selectedVersionIds[0];
  const version = versions.find(({ id }) => id === versionId);

  const [newVersionTitle, setNewVersionTitle] = useState("");
  const { organizationId } = useAppRouteParams();

  useEffect(() => {
    setNewVersionTitle(`${version?.title}_copy`);
  }, [version]);

  const runDuplicate = async () => {
    // NOTE: 簡易最適化は複製しない
    if (version && !checkIsPublishedByFileUpload(version)) {
      setDialogPhase("in_progress");
      try {
        await postDuplicateVersion({
          organizationId,
          versionId: version.id,
          title: newVersionTitle,
        });
        setDialogPhase("success");
      } catch (error) {
        showErrorAPIDialog(error);
      }
    }
  };
  const messages = getDialogMessages({
    isSome: false,
    actionType: "duplicate",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setNewVersionTitle("");
            setSelectedVersionIds([]);
          },
          element: (
            <div>
              <Input
                hidden={!selectedVersionIds.length}
                className={styles.versionTitleInput}
                value={newVersionTitle}
                onChange={(e) => setNewVersionTitle(e.target.value)}
              />
            </div>
          ),
          title: "バージョンの複製",
          isDisableButton: !newVersionTitle,
          displayMessage: "新しいバージョンの名前を入力してください。",
          buttonText: "複製",
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};
