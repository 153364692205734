import { ViewTypeConfigSchema } from "@/domain/ViewTypeConfigSchema";
import { getSheetSchemaTypeMap } from "../../schema/allDependSheets";

// ビュー内で利用することがあるすべてのスキーマを定義

export const DeliveryPlanViewComponentKey =
  "planiumChemicalDeliveryPlanView" as const;

export const DeliveryPlanViewAllSchemaTypeMap = getSheetSchemaTypeMap([
  "shippingPlan",
  "salesInput",
  "itemMaster",
  "storageMaster",
  "storageItemMaster",
  "params",
  "adjustmentInput",
  "batchTaskPlan",
  "batchTaskMaster",
  "businessDayCalendar",
  "batchTaskProcessTimeMaster",
  "deviceMaster",
  "purchasePlan",
  "batchTaskRawMaterialPlan",
  "batchTaskStoragePlan",
  "switchingBatchTaskMaster",
  "rawMaterialMaster",
  "scoreSummaries",
  "alertConfig",
]);

export const DeliveryPlanViewAllConfigSchema =
  [] as const as ViewTypeConfigSchema<"">[];

export type DeliveryPlanViewSchemaType =
  typeof DeliveryPlanViewAllSchemaTypeMap;
