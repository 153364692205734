import {
  FrontendAddonHeaderRawForm,
  FrontendAddonRaw,
  FrontendAddonRawForm,
  FrontendAddonTargetVersionRaw,
  FrontendAddonVersionRaw,
  FrontendAddonWithTargetVersionRaw,
} from "@/domain/FrontendAddon";
import { API_CLIENT } from "../config";
import { getAPIConfigs } from "../helpers";

const apiUrlFrontendAddons = "admin/frontend_addons";

export const getAdminFrontendAddons = async ({
  organizationId,
}: {
  organizationId: string;
}): Promise<FrontendAddonWithTargetVersionRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    items: FrontendAddonWithTargetVersionRaw[];
  }>(`${apiUrlFrontendAddons}`, {
    ...apiOrganizationIdParams,
  });
  return response.data.items;
};

export const getAdminFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonWithTargetVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{
    item: FrontendAddonWithTargetVersionRaw;
  }>(`${apiUrlFrontendAddons}/${frontendAddonId}`, {
    ...apiOrganizationIdParams,
  });
  return response.data.item;
};

export const postAdminCreateFrontendAddon = async ({
  organizationId,
  FrontendAddonRawForm,
  file,
}: {
  organizationId: string;
  FrontendAddonRawForm: FrontendAddonRawForm;
  file: File;
}): Promise<FrontendAddonRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  if (FrontendAddonRawForm.description) {
    form.append("description", FrontendAddonRawForm.description);
  }
  form.append("privacy_setting", FrontendAddonRawForm.privacy_setting);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlFrontendAddons}`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminEditFrontendAddonHeader = async ({
  organizationId,
  FrontendAddonHeaderRawForm,
  frontendAddonId,
}: {
  organizationId: string;
  FrontendAddonHeaderRawForm: FrontendAddonHeaderRawForm;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}`,
    FrontendAddonHeaderRawForm,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminDisableFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}/delete`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const postAdminEnableFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.post<{ item: FrontendAddonRaw }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}/enable`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const postAdminRemoveFrontendAddon = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post(
    `${apiUrlFrontendAddons}/${frontendAddonId}/remove`,
    undefined,
    {
      ...apiOrganizationIdParams,
    }
  );
};

export const getAdminFrontendAddonVersions = async ({
  organizationId,
  frontendAddonId,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
}): Promise<FrontendAddonVersionRaw[]> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const response = await API_CLIENT.get<{ items: FrontendAddonVersionRaw[] }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}/frontend_versions`,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.items;
};

export const postAdminCreateFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  file,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  file: File;
}): Promise<FrontendAddonVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  const response = await API_CLIENT.post<{ item: FrontendAddonVersionRaw }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}/frontend_versions`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminEditFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  FrontendAddonVersionId,
  file,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  FrontendAddonVersionId: FrontendAddonVersionRaw["id"];
  file: File;
}): Promise<FrontendAddonVersionRaw> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  const form = new FormData();
  form.append("file", file);
  const response = await API_CLIENT.post<{ item: FrontendAddonVersionRaw }>(
    `${apiUrlFrontendAddons}/${frontendAddonId}/frontend_versions/${FrontendAddonVersionId}`,
    form,
    {
      ...apiOrganizationIdParams,
    }
  );
  return response.data.item;
};

export const postAdminSetFrontendAddonVersion = async ({
  organizationId,
  frontendAddonId,
  targetVersion,
}: {
  organizationId: string;
  frontendAddonId: FrontendAddonRaw["id"];
  targetVersion: FrontendAddonTargetVersionRaw;
}): Promise<void> => {
  const { apiOrganizationIdParams } = getAPIConfigs(organizationId);
  await API_CLIENT.post(
    `${apiUrlFrontendAddons}/${frontendAddonId}/frontend_masters`,
    targetVersion,
    {
      ...apiOrganizationIdParams,
    }
  );
};
