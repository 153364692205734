import {
  AppFrontendMasterRaw,
  AppFrontendMasterRawForm,
  AppFrontendMasterVersionRaw,
} from "./AppFrontendMasters";
import { FrontendMasterRaw, FrontendMasterRawForm } from "./FrontendMaster";

type PartialNotNullable<T, K extends keyof T> = Omit<T, K> & {
  [P in K]: NonNullable<T[P]>;
};

export type FrontendAddonWithVersionInfoRaw = Omit<
  AppFrontendMasterRaw,
  "latest_version" | "privacy_setting" | "owner_org_id"
> &
  Pick<AppFrontendMasterVersionRaw, "path_prefix">;

export type FrontendAddonWithVersionInfo = {
  createdAt: Date;
  description: string | null;
  id: string;
  isDisabled: boolean;
  name: string;
  pathPrefix: string;
  updatedAt: Date;
};
export const parseFrontendAddonWithVersionInfoRaw = ({
  created_at,
  description,
  id,
  is_disabled,
  name,
  path_prefix,
  updated_at,
}: FrontendAddonWithVersionInfoRaw) => ({
  createdAt: new Date(created_at),
  description: description,
  id,
  isDisabled: is_disabled,
  name,
  pathPrefix: path_prefix,
  updatedAt: new Date(updated_at),
});

export type FrontendAddonRaw = AppFrontendMasterRaw;

export type FrontendAddonWithTargetVersionRaw = AppFrontendMasterRaw &
  Pick<FrontendMasterRaw, "target_version"> & {
    target_version_created_at: FrontendMasterRaw["created_at"];
    target_version_updated_at: FrontendMasterRaw["updated_at"];
  } & Pick<AppFrontendMasterVersionRaw, "path_prefix">;

export type FrontendAddonRawForm = PartialNotNullable<
  AppFrontendMasterRawForm,
  "owner_org_id"
>;

export const initialFrontendAddonRawForm: FrontendAddonRawForm = {
  description: "",
  privacy_setting: "private",
  owner_org_id: "",
} as const;

export type FrontendAddonHeaderRawForm = Omit<
  AppFrontendMasterRawForm,
  "owner_org_id"
>;

export const initialFrontendAddonHeaderRawForm: FrontendAddonHeaderRawForm = {
  description: "",
  privacy_setting: "private",
} as const;

export type FrontendAddonVersionRaw = AppFrontendMasterVersionRaw;

export type FrontendAddonTargetVersionRaw = Pick<
  FrontendMasterRawForm,
  "target_version"
>;
