import loadable from "@loadable/component";

import { dependSheets, loadSkipSheets } from "./autogen";
import { StrictViewType } from "./types";

const ViewRender = loadable(() => import("./render"));

export const viewTypeNshKwsSpreadSheetAndGraph4s: StrictViewType = {
  key: "nshKwsSpreadSheetAndGraph4s",
  displayName: "NSH-KWSスプレッドシート第四製造課",
  render: ViewRender as any,
  dependSheets: dependSheets,
  configSchemas: [],
  querySettings: loadSkipSheets.map((sheetInfo) => ({
    sheetKey: sheetInfo.sheetKey,
    sheetFieldSchemaName: sheetInfo.sheetFieldSchemaName,
    queryFn: () => {
      return [
        {
          opStr: "==",
          value: "",
        },
      ];
    },
  })),
};
