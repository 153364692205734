import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useAppRouteParams } from "../../../../../AppRoutes";
import {
  View,
  useRecursiveDeleteView,
  useUpdateView,
} from "../../../../../domain/View";
import { ManagedTransaction } from "../../../../../firebase/firestore";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

export const DialogViewDelete: React.FC<
  {
    views: View[];
    selectedViewIds: string[];
    setSelectedViewIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  views,
  selectedViewIds,
  setSelectedViewIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const navigate = useNavigate();
  const { organizationId, versionId, viewId: nowViewId } = useAppRouteParams();
  const recursiveDeleteView = useRecursiveDeleteView();
  const updateView = useUpdateView();

  const { renderDialogs, showErrorAPIDialog, showErrorLocalDialog } = useDialog(
    {
      dialogPhase,
      setDialogPhase,
    }
  );

  const runDelete = async () => {
    if (nowViewId && selectedViewIds.includes(nowViewId)) {
      navigate(`/organizations/${organizationId}/versions/${versionId}`);
    }
    setDialogPhase("in_progress");
    const otherViews = views.filter(({ id }) => !selectedViewIds.includes(id));
    try {
      const deleteQueueList = await Promise.all(
        selectedViewIds.map((viewId) => recursiveDeleteView(viewId, {}))
      );
      await ManagedTransaction.runTransaction(async (transaction) => {
        // view削除
        for (const deleteQueue of deleteQueueList) {
          await deleteQueue.exec(transaction);
        }
        // ソート番号振り直し
        for (const [index, view] of otherViews.entries()) {
          await updateView(
            { ...view, sort: index },
            { viewId: view.id },
            { transaction }
          );
        }
      });
      setSelectedViewIds([]);
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };

  const messages = getDialogMessages({
    isSome: false,
    actionType: "delete",
    targetType: "view",
  });
  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDelete,
          onClickCancel: () => {
            setSelectedViewIds([]);
          },
          isThemeRed: true,
          ...messages.confirm,
        },
        inProgress: {
          ...messages.inProgress,
        },
        success: {
          ...messages.success,
        },
        errorAPI: {
          ...messages.error,
        },
      })}
    </>
  );
};
