import React from "react";

import { useDialogStyles, useWarningButtonStyles } from "@/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from "@fluentui/react-components";
import { DialogConfirmParam } from "../hooks";

export const DialogConfirm: React.FC<DialogConfirmParam> = ({
  title,
  displayMessage,
  buttonText,
  onClick,
  onClickCancel,
  dialogPhase,
  setDialogPhase,
  element,
  isThemeRed,
  isDisableButton = false,
}) => {
  const buttonClasses = useWarningButtonStyles();
  const dialogClasses = useDialogStyles();
  return (
    <Dialog
      onOpenChange={() => setDialogPhase(null)}
      open={dialogPhase === "confirm"}
    >
      <DialogSurface className={dialogClasses.dialog}>
        <DialogBody>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {displayMessage}
            {element}
          </DialogContent>
          <DialogActions>
            <Button
              appearance={isThemeRed ? undefined : "primary"}
              onClick={() => {
                onClick();
              }}
              disabled={isDisableButton}
              className={isThemeRed ? buttonClasses.warning : ""}
            >
              {buttonText}
            </Button>
            <Button
              onClick={() => {
                onClickCancel && onClickCancel();
                setDialogPhase(null);
              }}
            >
              戻る
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
