import loadable from "@loadable/component";
import React from "react";
import { ViewType } from "../../../../../../domain/ViewType";
import {
  CargoHandling,
  CargoMaster,
  ReceivingPatternMaster,
} from "../../depotView/domains/Cargo";
import { CompanyMaster } from "../../depotView/domains/Company";
import { DepotStockConfig } from "../../depotView/domains/Config";
import { DtReceivingColumnControlMaster } from "../../depotView/domains/DtReceivingColumnControl";
import { DtRunningInput } from "../../depotView/domains/DtRunningInput";
import { DepotStockOilMaster } from "../../depotView/domains/Oil";
import { DepotStockPortMaster } from "../../depotView/domains/Port";
import { DtTankMaster } from "../../depotView/domains/Tank";
import { VoyagePlan } from "../../domains";
import { MasterSheetsType } from "../../types";
import { VoyagePlanSheetType } from "../../types/VoyagePlanSheetType";

const ViewRender = loadable(() => import("./render"));

export const viewTypeDepotStockTableView: ViewType<
  ViewTypeDepotStockTableView,
  ""
> = {
  key: "cptdomDepotStockTableView",
  displayName: "CPT-DOM 油槽所在庫表ビュー",
  render: ViewRender as any,
  dependSheets: [
    {
      key: "config",
      displayName: "設定",
      dependSheetFields: [
        {
          key: "main_oil_group",
          displayName: "main_oil_group",
          type: "enum",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
      ],
    },
    {
      key: "portMaster",
      displayName: "基地マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "port_name",
          displayName: "port_name",
          type: "string",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "oilMaster",
      displayName: "油種マスタ",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "oil_name",
          displayName: "oil_name",
          type: "string",
        },
        {
          key: "main_oil_group",
          displayName: "main_oil_group",
          type: "enum",
        },
      ],
    },
    {
      key: "dtTankMaster",
      displayName: "油槽所タンクマスタ",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "capacity",
          displayName: "capacity",
          type: "float",
        },
        {
          key: "dead",
          displayName: "dead",
          type: "float",
        },
        {
          key: "since",
          displayName: "since",
          type: "date",
        },
        {
          key: "label",
          displayName: "label",
          type: "string",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
      ],
    },
    {
      key: "companyMaster",
      displayName: "会社マスタ",
      dependSheetFields: [
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
        {
          key: "company_name",
          displayName: "company_name",
          type: "string",
        },
      ],
    },
    {
      key: "dtReceivingColumnControlMaster",
      displayName: "受入列制御マスタ",
      dependSheetFields: [
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
        {
          key: "ship_owner_company_code",
          displayName: "ship_owner_company_code",
          type: "string",
        },
        {
          key: "is_shown",
          displayName: "is_shown",
          type: "boolean",
        },
      ],
    },
    {
      key: "dtRunningInput",
      displayName: "油槽所操業入力",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "cosmo_receiving_amount_from_cosmo",
          displayName: "cosmo_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "cosmo_receiving_amount_from_eneos",
          displayName: "cosmo_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "cosmo_receiving_amount_from_idemitsu",
          displayName: "cosmo_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_cosmo",
          displayName: "eneos_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_eneos",
          displayName: "eneos_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_idemitsu",
          displayName: "eneos_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_cosmo",
          displayName: "idemitsu_receiving_amount_from_cosmo",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_eneos",
          displayName: "idemitsu_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_idemitsu",
          displayName: "idemitsu_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "shipping_amount_cosmo",
          displayName: "shipping_amount_cosmo",
          type: "float",
        },
        {
          key: "shipping_amount_eneos",
          displayName: "shipping_amount_eneos",
          type: "float",
        },
        {
          key: "shipping_amount_idemitsu",
          displayName: "shipping_amount_idemitsu",
          type: "float",
        },
        {
          key: "actual_stock_cosmo",
          displayName: "actual_stock_cosmo",
          type: "float",
        },
        {
          key: "actual_stock_eneos",
          displayName: "actual_stock_eneos",
          type: "float",
        },
        {
          key: "actual_stock_idemitsu",
          displayName: "actual_stock_idemitsu",
          type: "float",
        },
        {
          key: "port_code_yyyymm",
          displayName: "port_code_yyyymm",
          type: "string",
        },
      ],
    },
    {
      key: "dtRunningOverwriteInput",
      displayName: "油槽所操業上書き入力",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },

        {
          key: "cosmo_receiving_amount_from_eneos",
          displayName: "cosmo_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "cosmo_receiving_amount_from_idemitsu",
          displayName: "cosmo_receiving_amount_from_idemitsu",
          type: "float",
        },

        {
          key: "eneos_receiving_amount_from_eneos",
          displayName: "eneos_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "eneos_receiving_amount_from_idemitsu",
          displayName: "eneos_receiving_amount_from_idemitsu",
          type: "float",
        },

        {
          key: "idemitsu_receiving_amount_from_eneos",
          displayName: "idemitsu_receiving_amount_from_eneos",
          type: "float",
        },
        {
          key: "idemitsu_receiving_amount_from_idemitsu",
          displayName: "idemitsu_receiving_amount_from_idemitsu",
          type: "float",
        },
        {
          key: "shipping_amount_cosmo",
          displayName: "shipping_amount_cosmo",
          type: "float",
        },
        {
          key: "shipping_amount_eneos",
          displayName: "shipping_amount_eneos",
          type: "float",
        },
        {
          key: "shipping_amount_idemitsu",
          displayName: "shipping_amount_idemitsu",
          type: "float",
        },
        {
          key: "actual_stock_cosmo",
          displayName: "actual_stock_cosmo",
          type: "float",
        },
        {
          key: "actual_stock_eneos",
          displayName: "actual_stock_eneos",
          type: "float",
        },
        {
          key: "actual_stock_idemitsu",
          displayName: "actual_stock_idemitsu",
          type: "float",
        },
        {
          key: "port_code_yyyymm",
          displayName: "port_code_yyyymm",
          type: "string",
        },
      ],
    },
    {
      key: "cargoHandling",
      displayName: "荷役",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "cargo_handling_id",
          displayName: "cargo_handling_id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "berth_code",
          displayName: "berth_code",
          type: "string",
        },
        {
          key: "date",
          displayName: "date",
          type: "date",
        },
        {
          key: "handling_type",
          displayName: "handling_type",
          type: "enum",
        },
      ],
    },
    {
      key: "cargo",
      displayName: "貨物",
      dependSheetFields: [
        {
          key: "oil_code",
          displayName: "oil_code",
          type: "string",
        },
        {
          key: "oil_amount",
          displayName: "oil_amount",
          type: "float",
        },
        {
          key: "load_id",
          displayName: "load_id",
          type: "string",
        },
        {
          key: "unload_id",
          displayName: "unload_id",
          type: "string",
        },
        {
          key: "company_code",
          displayName: "company_code",
          type: "string",
        },
      ],
    },
    {
      key: "receivingPatternMaster",
      displayName: "入荷ルールマスタ",
      dependSheetFields: [
        {
          key: "id",
          displayName: "id",
          type: "string",
        },
        {
          key: "port_code",
          displayName: "port_code",
          type: "string",
        },
        {
          key: "oil_code_on_ship",
          displayName: "oil_code_on_ship",
          type: "string",
        },
        {
          key: "target_oil_code",
          displayName: "target_oil_code",
          type: "string",
        },
      ],
    },
    {
      key: "voyagePlan",
      displayName: "転送計画",
      dependSheetFields: [
        {
          key: "task_id",
          displayName: "task_id",
          type: "string",
        },
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "is_fixed",
          displayName: "is_fixed",
          type: "boolean",
        },
        {
          key: "fixed_mode",
          displayName: "fixed_mode",
          type: "enum",
        },
        {
          key: "time_calculation_mode",
          displayName: "time_calculation_mode",
          type: "enum",
        },
        {
          key: "ship_fixed_mode",
          displayName: "ship_fixed_mode",
          type: "enum",
        },
        {
          key: "is_berth_fixed",
          displayName: "is_berth_fixed",
          type: "boolean",
        },
        {
          key: "comment",
          displayName: "comment",
          type: "string",
        },
        {
          key: "non_loading_flag",
          displayName: "non_loading_flag",
          type: "string",
        },
        {
          key: "color_on_ship_allocation_view",
          displayName: "color_on_ship_allocation_view",
          type: "string",
        },
        {
          key: "color_on_berth_schedule_view",
          displayName: "color_on_berth_schedule_view",
          type: "string",
        },
      ],
    },
    {
      key: "shipMaster",
      displayName: "船マスタ",
      dependSheetFields: [
        {
          key: "ship_code",
          displayName: "ship_code",
          type: "string",
        },
        {
          key: "ship_name",
          displayName: "ship_name",
          type: "string",
        },
        {
          key: "ship_size",
          displayName: "ship_size",
          type: "string",
        },
        {
          key: "oil_group",
          displayName: "oil_group",
          type: "enum",
        },
        {
          key: "display_order",
          displayName: "display_order",
          type: "integer",
        },
        {
          key: "ship_type",
          displayName: "ship_type",
          type: "enum",
        },
        {
          key: "is_shown",
          displayName: "is_shown",
          type: "boolean",
        },
        {
          key: "is_optimization_target",
          displayName: "is_optimization_target",
          type: "boolean",
        },
      ],
    },
  ],
  configSchemas: [],
  noSwitchLoadingComponentOnChange: true,
};

export type ViewTypeDepotStockTableView = {
  config: DepotStockConfig;
  portMaster: DepotStockPortMaster;
  oilMaster: DepotStockOilMaster;
  dtTankMaster: DtTankMaster;
  companyMaster: CompanyMaster;
  dtReceivingColumnControlMaster: DtReceivingColumnControlMaster;
  dtRunningInput: DtRunningInput;
  dtRunningOverwriteInput: DtRunningInput;
  cargoHandling: CargoHandling;
  cargo: CargoMaster;
  receivingPatternMaster: ReceivingPatternMaster;
  voyagePlan: VoyagePlanSheetType;
  shipMaster: MasterSheetsType["shipMaster"];
};

export type DepotStockViewStrictViewType = ViewType<
  ViewTypeDepotStockTableView,
  ""
>;

export type DepotStockViewSheetsData = React.ComponentProps<
  DepotStockViewStrictViewType["render"]
>["sheetsData"];
