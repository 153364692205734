import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";
import "./ViewDependSheetField";

/**
 * @param id firestore上の識別子
 * @param displayName 依存シートの表示名。ViewTypeのdependSheets.displayNameに相当
 * @param key フロント上の識別子。ViewTypeのdependSheets.keyに相当
 * @param sheetId 依存シートID。referencing sheetか否かに関わらず必ず持つ
 * @param refVersionId 依存シートが含まれるバージョンのID。referencing sheetの場合のみ持つ
 */
export type ViewDependSheet<K extends string = string> = {
  id: string;
  displayName: string;
  key: K;
  sheetId: string;
  refVersionId: string | null;
};
type ViewDependSheetRaw = {
  id: string;
  display_name: string;
  key: string;
  sheet_id: string;
  ref_version_id: string | null;
};
export const checkViewDependSheetHasBlankId = (v: ViewDependSheet) => {
  return v.id === "" || v.sheetId === "" || v.refVersionId === "";
};

const processorOptions: FirestoreCollectionProsessorOptions<
  ViewDependSheet,
  ViewDependSheetRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId, viewId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/views/${viewId}/view_depend_sheets`,
  toFirestore: ({ id, displayName, key, sheetId, refVersionId }) => ({
    id,
    display_name: displayName,
    key,
    sheet_id: sheetId,
    ref_version_id: refVersionId ?? null,
  }),
  fromFirestore: ({ id, display_name, key, sheet_id, ref_version_id }) => ({
    id,
    displayName: display_name,
    key,
    sheetId: sheet_id,
    refVersionId: ref_version_id ?? null,
  }),
};

export const {
  useGetAll: useGetViewDependSheets,
  useGet: useGetViewDependSheet,
  useSet: useSetViewDependSheet,
  useUpdate: useUpdateViewDependSheet,
  useRecursiveDelete: useRecursiveDeleteViewDependSheet,
  useItem: useViewDependSheet,
  useItems: useViewDependSheets,
  useSomeItems: useSomeViewDependSheets,
} = createFirestoreCollectionProsessor(processorOptions);
