import { WhereFilterOp } from "firebase/firestore";

import {
  FirestoreCollectionProsessorOptions,
  createFirestoreCollectionProsessor,
} from "../firebase/firestore";

import "./SheetDataRow";
import "./SheetFieldSchema";
import "./ReferredFrom";

export const sheetOptimizeTypeList = [
  "both",
  "output_only",
  "input_only",
  "input_params",
  "input_weight_raw",
  "result_score_summaries",
  "result_alert_details",
  "report",
  "none",
  null,
] as const;

export const sheetOptimizeTypeGroupList = [
  "main",
  "output",
  "input",
  "params",
  "results",
  "others",
] as const;

export const sheetOpenAPITypeList = ["object", "array"] as const;
export const sheetAlgorithmExcelSheetTypeList = [
  "dataframe",
  "keyvalue",
] as const;

type SheetOptimizeTypes = (typeof sheetOptimizeTypeList)[number];
export type SheetOptimizeTypesGroup =
  (typeof sheetOptimizeTypeGroupList)[number];
type SheetOpenAPIType = (typeof sheetOpenAPITypeList)[number];
type SheetAlgorithmExcelSheetType =
  (typeof sheetAlgorithmExcelSheetTypeList)[number];

export const sheetOptimizeTypeMap = new Map<
  SheetOptimizeTypesGroup,
  (typeof sheetOptimizeTypeList)[number][]
>([
  ["main", ["both"]],
  ["input", ["input_only"]],
  ["output", ["output_only"]],
  ["params", ["input_params", "input_weight_raw"]],
  ["results", ["result_score_summaries", "result_alert_details", "report"]],
  ["others", ["none", null]],
]);

export type Sheet = {
  id: string;
  displayName: string;
  name: string;
  firstRowId: string | null;
  algorithmExcelDataStartRow: string | null;
  algorithmExcelHeaderRow: string | null;
  algorithmExcelSheetType: SheetAlgorithmExcelSheetType | null;
  createdAt: string;
  updatedAt: string;
  description: string | null;
  isDisabled: boolean;
  isLockedData: boolean;
  isLockedDataCreate: boolean;
  isLockedSchema: boolean;
  primaryKeyFieldIds: string[];
  sheetOptimizeType: SheetOptimizeTypes;
  openapiType: SheetOpenAPIType | null;
  sort: number;
  isSheetQueryOn: boolean;
  sheetQuerySheetFieldSchemaId: string;
  sheetQueryParameters: {
    value: unknown;
    opStr: WhereFilterOp;
  }[];
  referTo: {
    versionId: string;
    sheetId: string;
  } | null;
  lastTransactionId?: string;
};
type SheetRaw = {
  id: string;
  display_name: string;
  name: string;
  first_row_id: string | null;
  algorithm_excel_data_start_row: string | null;
  algorithm_excel_header_row: string | null;
  algorithm_excel_sheet_type: SheetAlgorithmExcelSheetType | null;
  created_at: string;
  updated_at: string;
  description: string | null;
  is_disabled: boolean;
  is_locked_data: boolean;
  is_locked_data_create: boolean;
  is_locked_schema: boolean;
  primary_key_field_ids: string[];
  sheet_optimize_type: SheetOptimizeTypes;
  openapi_type: SheetOpenAPIType | null;
  sort: number;
  sheet_query_on: boolean;
  sheet_query_sheet_field_schema_id: string;
  sheet_query_parameters: {
    value: unknown;
    op_str: WhereFilterOp;
  }[];
  refer_to: {
    version_id: string;
    sheet_id: string;
  } | null;
  last_transaction_id?: string;
};

const processorOptions: FirestoreCollectionProsessorOptions<
  Sheet,
  SheetRaw,
  Record<string, string>
> = {
  getCollectionPath: ({ organizationId, versionId }) =>
    `/app/spreadsheet_ui/v/1/organizations/${organizationId}/versions/${versionId}/sheets`,
  toFirestore: ({
    id,
    displayName,
    name,
    firstRowId,
    algorithmExcelDataStartRow,
    algorithmExcelHeaderRow,
    algorithmExcelSheetType,
    createdAt,
    updatedAt,
    description,
    isDisabled,
    isLockedData,
    isLockedDataCreate,
    isLockedSchema,
    primaryKeyFieldIds,
    sheetOptimizeType,
    openapiType,
    sort,
    isSheetQueryOn,
    sheetQuerySheetFieldSchemaId,
    sheetQueryParameters,
    referTo,
    lastTransactionId,
  }) => ({
    id,
    display_name: displayName,
    name,
    first_row_id: firstRowId,
    algorithm_excel_data_start_row: algorithmExcelDataStartRow,
    algorithm_excel_header_row: algorithmExcelHeaderRow,
    algorithm_excel_sheet_type: algorithmExcelSheetType,
    created_at: createdAt,
    updated_at: updatedAt,
    description,
    is_disabled: isDisabled,
    is_locked_data: isLockedData,
    is_locked_data_create: isLockedDataCreate,
    is_locked_schema: isLockedSchema,
    primary_key_field_ids: primaryKeyFieldIds,
    sheet_optimize_type: sheetOptimizeType,
    openapi_type: openapiType,
    sort,
    sheet_query_on: isSheetQueryOn,
    sheet_query_sheet_field_schema_id: sheetQuerySheetFieldSchemaId,
    sheet_query_parameters: sheetQueryParameters.map(
      ({ value, opStr: op_str }) => ({ value, op_str })
    ),
    refer_to: referTo
      ? {
          version_id: referTo.versionId,
          sheet_id: referTo.sheetId,
        }
      : null,
    ...(lastTransactionId ? { last_transaction_id: lastTransactionId } : {}),
  }),
  fromFirestore: ({
    id,
    display_name,
    name,
    first_row_id,
    algorithm_excel_data_start_row,
    algorithm_excel_header_row,
    algorithm_excel_sheet_type,
    created_at,
    updated_at,
    description,
    is_disabled,
    is_locked_data,
    is_locked_data_create,
    is_locked_schema,
    primary_key_field_ids,
    sheet_optimize_type,
    openapi_type,
    sort,
    sheet_query_on,
    sheet_query_sheet_field_schema_id,
    sheet_query_parameters,
    refer_to,
    last_transaction_id,
  }) => ({
    id,
    displayName: display_name,
    name,
    firstRowId: first_row_id,
    algorithmExcelDataStartRow: algorithm_excel_data_start_row,
    algorithmExcelHeaderRow: algorithm_excel_header_row,
    algorithmExcelSheetType: algorithm_excel_sheet_type,
    createdAt: created_at,
    updatedAt: updated_at,
    description,
    isDisabled: is_disabled,
    isLockedData: is_locked_data,
    isLockedDataCreate: is_locked_data_create,
    isLockedSchema: is_locked_schema,
    primaryKeyFieldIds: primary_key_field_ids,
    sheetOptimizeType: sheet_optimize_type,
    openapiType: openapi_type,
    sort,
    isSheetQueryOn: sheet_query_on,
    sheetQuerySheetFieldSchemaId: sheet_query_sheet_field_schema_id,
    sheetQueryParameters: sheet_query_parameters.map(
      ({ value, op_str: opStr }) => ({ value, opStr })
    ),
    referTo: refer_to
      ? {
          versionId: refer_to.version_id,
          sheetId: refer_to.sheet_id,
        }
      : null,
    ...(last_transaction_id ? { lastTransactionId: last_transaction_id } : {}),
  }),
};

export const {
  useGetAll: useGetSheets,
  useGet: useGetSheet,
  useSet: useSetSheet,
  useUpdate: useUpdateSheet,
  useRecursiveDelete: useRecursiveDeleteSheet,
  useItem: useSheet,
  useItemsQuery: useSheetsQuery,
} = createFirestoreCollectionProsessor(processorOptions);
