import { DefaultButton } from "@fluentui/react";
import React, { useCallback, useMemo, useState } from "react";

import { Version } from "../../../..//domain/Version";
import { VersionOptimizeStatus } from "../../../..//domain/Version";
import { useAppRouteParams } from "../../../../AppRoutes";
import { Sheet } from "../../../../domain/Sheet";
import { useDialogContextMenuItemsVersion } from "../../../Dialog/DialogFirestore/DialogVersion//hooks";
import { SortableTmpMenuSet } from "../../helpers";
import { TmpMenuSetOptimizeSummary } from "../../helpers";

import styles from "./index.module.css";

import { useAppOrgsAndInvitedOrgs } from "@/components/AppProvider/AppOrgsAndInvitedOrgsProvider";
import { useVersionCategories } from "@/components/InOrganizationProvider/VersionCategoriesProvider";
import { useAuthorizedSheets } from "@/hooks/useAuthorizedCollections";
import { useAppSideNavGlobalContext } from "../../providers/AppSideNavGlobalContextProvider";

const DEFAULT_LIST_LENGTH = 5;

const versionOptimizeStatusIconList: {
  [key in Exclude<VersionOptimizeStatus, null>]: {
    iconName: string;
    color: string;
  };
} & { null: { iconName: string; color: string } } = {
  queued: { iconName: "CirclePause", color: "#FFF" },
  in_progress_evaluation: {
    iconName: "ProgressRingDots",
    color: "var(--figma-color-theme-primary)",
  },
  success_evaluation: {
    iconName: "LocationDot",
    color: "var(--figma-color-success-green)",
  },
  queued_optimization: { iconName: "CirclePause", color: "#FFF" },
  in_progress_optimization: {
    iconName: "ProgressRingDots",
    color: "var(--figma-color-theme-primary)",
  },
  success_optimization: {
    iconName: "LocationDot",
    color: "var(--figma-color-success-green)",
  },
  failure_in_optimization: {
    iconName: "ErrorBadge",
    color: "red",
  },
  failure_input_evaluation: {
    iconName: "ErrorBadge",
    color: "red",
  },
  failure_exception: {
    iconName: "ErrorBadge",
    color: "red",
  },
  null: { iconName: "CircleRing", color: "#FFF" },
};

export const AppSideNavGlobalVersions: React.FC<{
  versionCategoryId: string;
  versions: Version[];
}> = ({ versionCategoryId, versions }) => {
  const { organizationId, versionId } = useAppRouteParams();
  const { organizations } = useAppOrgsAndInvitedOrgs();
  const { versionCategories } = useVersionCategories();
  const targetVersionCategory = useMemo(
    () =>
      versionCategories?.find((category) => category.id === versionCategoryId),
    [versionCategories, versionCategoryId]
  );
  const organization = organizations.find(
    (org) => org.org_id === organizationId
  );
  const {
    categoriesMap,
    toggleIsCloseCategoryVersions,
    toggleShowAllCategoryVersions,
  } = useAppSideNavGlobalContext();
  const categoryState = categoriesMap.get(versionCategoryId);
  const { isClose, isShowAll } = categoryState ?? {
    isClose: false,
    isShowAll: false,
  };

  const slicedVersions = useMemo(() => {
    return versions.slice(0, DEFAULT_LIST_LENGTH);
  }, [versions]);

  const { renderDialogsVersion, getContextMenuItemsVersion } =
    useDialogContextMenuItemsVersion();
  const isVisibleMenu = organization?.is_sheet_enabled === true;

  // NOTE: 選択バージョンの比較基準サマリ
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);
  const compareTargetResultScoreSummary: Sheet | null = useMemo(
    () =>
      sheets.find(
        ({ sheetOptimizeType }) =>
          sheetOptimizeType === "result_score_summaries"
      ) ?? null,
    [sheets]
  );

  return (
    <div className={styles.rootItems}>
      <span className={styles.closeOpenMenu}>
        <SortableTmpMenuSet
          id={versionCategoryId}
          text={`${targetVersionCategory?.name ?? "未分類"} ( ${versions.length} )`}
          iconName={isClose ? "ChevronDown" : "ChevronUp"}
          onClick={() => toggleIsCloseCategoryVersions(versionCategoryId)}
          isDisableTooltip={true}
        />
      </span>
      {!isClose ? (
        <div>
          {
            // パフォーマンス向上のため、デフォルトで表示するのは最新${DEFAULT_LIST_LENGTH}件のみ
            (isShowAll ? versions : slicedVersions).map((version) => {
              const { iconName, color: iconColor } = version.optimizeStatus
                ? versionOptimizeStatusIconList[version.optimizeStatus]
                : versionOptimizeStatusIconList["null"];
              return (
                <SortableTmpMenuSet
                  id={version.id}
                  key={version.id}
                  text={version.title}
                  iconName={iconName}
                  iconColor={iconColor}
                  to={`/organizations/${organizationId}/versions/${version.id}`}
                  isChildren={true}
                  active={version.id === versionId}
                  contextMenuItems={getContextMenuItemsVersion(version)}
                  nestedElement={
                    isVisibleMenu ? (
                      <TmpMenuSetOptimizeSummary
                        version={version}
                        compareTargetResultScoreSummary={
                          compareTargetResultScoreSummary
                        }
                      />
                    ) : undefined
                  }
                />
              );
            })
          }
        </div>
      ) : null}
      {!isClose && versions.length > DEFAULT_LIST_LENGTH && (
        <DefaultButton
          iconProps={{
            iconName: `${isShowAll ? "ChevronUpSmall" : "ChevronDownSmall"}`,
          }}
          className={styles.showAllVersionsButton}
          onClick={() => toggleShowAllCategoryVersions(versionCategoryId)}
          text={
            isShowAll ? `最新${DEFAULT_LIST_LENGTH}件のみ表示` : "全て表示する"
          }
        />
      )}
      {renderDialogsVersion(isShowAll ? versions : slicedVersions)}
    </div>
  );
};
