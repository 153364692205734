import { ViewTypeConfigSchema } from "@/domain/ViewTypeConfigSchema";
import { getSheetSchemaTypeMap } from "../../schema/allDependSheets";

// ビュー内で利用することがあるすべてのスキーマを定義

export const ItemStockViewComponentKey =
  "planiumChemicalItemStockView" as const;

export const ItemStockViewAllSchemaTypeMap = getSheetSchemaTypeMap([
  "batchTaskPlan",
  "shippingPlan",
  "purchasePlan",
  "businessDayCalendar",
  "adjustmentInput",
  "salesInput",
  "batchTaskMaster",
  "params",
  "batchTaskStoragePlan",
  "batchTaskRawMaterialPlan",
  "itemMaster",
  "deviceMaster",
  "batchTaskProcessTimeMaster",
  "switchingBatchTaskMaster",
  "utilityCostTypeMaster",
  "batchTaskUtilityMaster",
  "deviceMaintenanceInput",
  "storageMaster",
  "stockCriteriaMaster",
  "storageItemMaster",
  "alertConfig",
]);

export const ItemStockViewAllConfigSchema = [
  {
    key: "itemStorageType",
    displayName: "品目保管所タイプ(','区切りで複数入力可)",
    type: "string",
    typeFields: null,
    typeParameters: null,
  },
] as const as ViewTypeConfigSchema<"itemStorageType">[];

export type ItemStockViewSchemaType = typeof ItemStockViewAllSchemaTypeMap;
