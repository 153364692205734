import { Input } from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { Version } from "../../../../../domain/Version";
import { DialogPhaseStatesType, useDialog } from "../../../helpers/hooks";
import { getDialogMessages } from "../../helper";

import { useAppRouteParams } from "../../../../../AppRoutes";
import { postDuplicateVersion } from "../../../../../api/version";
import styles from "./index.module.css";

export const DialogVersionDuplicateSome: React.FC<
  {
    versions: Version[];
    selectedVersionIds: string[];
    setSelectedVersionIds: React.Dispatch<React.SetStateAction<string[]>>;
  } & DialogPhaseStatesType
> = ({
  versions,
  selectedVersionIds,
  setSelectedVersionIds,
  dialogPhase,
  setDialogPhase,
}) => {
  const { renderDialogs, showErrorAPIDialog } = useDialog({
    dialogPhase,
    setDialogPhase,
  });

  const selectedVersions = versions.filter(({ id }) =>
    selectedVersionIds.includes(id)
  );

  const [versionTitles, setVersionTitles] = useState<
    { oldTitle: string; newTitle: string }[]
  >([]);

  const { organizationId } = useAppRouteParams();

  useEffect(() => {
    setVersionTitles(
      selectedVersions.map((version) => ({
        oldTitle: version.title,
        newTitle: `${version.title}_copy`,
      }))
    );
  }, [selectedVersionIds]);

  const progressTotal = selectedVersionIds.length;
  const [progressCount, setProgressCount] = useState(0);

  const runDuplicate = async () => {
    setProgressCount(0);
    setDialogPhase("in_progress");
    try {
      for (const [idx, version] of selectedVersions.entries()) {
        await postDuplicateVersion({
          organizationId,
          versionId: version.id,
          title: versionTitles[idx].newTitle,
        });
        setProgressCount((cnt) => cnt + 1);
        // APIの実行時の制限に従い、1秒待機
        // ref: レートリミット: どの1秒間(ウィンドウ)を見ても、ある呼び出しユーザによるAPI実行は最大で1つしか存在しないこと。
        // そのため、APIの実行時に1秒待機することで、レートリミットを回避する。
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      setDialogPhase("success");
    } catch (error) {
      showErrorAPIDialog(error);
    }
  };
  const messages = getDialogMessages({
    isSome: true,
    actionType: "duplicate",
    targetType: "version",
  });

  return (
    <>
      {renderDialogs({
        confirm: {
          onClick: runDuplicate,
          onClickCancel: () => {
            setVersionTitles([]);
            setSelectedVersionIds([]);
          },
          element: (
            <div>
              {versionTitles.map(({ oldTitle, newTitle }, idx) => (
                <div key={`version-title-${idx}`} className={styles.inputRow}>
                  {oldTitle}
                  <Input
                    className={styles.versionTitleInput}
                    value={newTitle}
                    onChange={(e) =>
                      setVersionTitles((prev) =>
                        prev.map((v, i) =>
                          i === idx ? { ...v, newTitle: e.target.value } : v
                        )
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ),
          title: "バージョンの複製",
          isDisableButton:
            versionTitles.some(({ newTitle }) => !newTitle) ||
            selectedVersions.length !== versionTitles.length,
          displayMessage: "新しいバージョンの名前を入力してください。",
          buttonText: "複製",
        },
        inProgress: {
          ...messages.inProgress,
          count: {
            current: progressCount,
            total: progressTotal,
          },
        },
        success: {
          onClick: () => {
            setSelectedVersionIds([]);
          },
          ...messages.success,
        },
        errorAPI: { ...messages.error },
      })}
    </>
  );
};
