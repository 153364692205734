import { useEffect, useState } from "react";

import { useAppRouteParams } from "@/AppRoutes";
import { Sheet } from "@/domain/Sheet";
import {
  useAuthorizedSheets,
  useGetAuthorizedSheet,
} from "./useAuthorizedCollections";

// 参照先のシートのリストを取得するカスタムフック
export const useReferencedSheets = (): [
  (Sheet & { refVersionId: string })[],
  boolean,
] => {
  const { versionId } = useAppRouteParams();
  const [sheets, isLoadedSheets] = useAuthorizedSheets(versionId);
  const [referencedSheets, setReferencedSheets] = useState<
    (Sheet & { refVersionId: string })[]
  >([]);
  const getSheet = useGetAuthorizedSheet();
  const [isInProcess, setIsInProcess] = useState(true);

  useEffect(() => {
    // NOTE: シートがロードされていなければ、初期化する
    if (!isLoadedSheets) {
      setIsInProcess(true);
      setReferencedSheets([]);
      return;
    }
    (async () => {
      const newReferencedSheets: (Sheet & { refVersionId: string })[] = [];
      const loadSheetTasks = sheets.map(async (sheet) => {
        if (!sheet.referTo) {
          return null;
        }
        const referencedSheet = await getSheet({
          versionId: sheet.referTo.versionId,
          sheetId: sheet.referTo.sheetId,
        });
        if (referencedSheet) {
          return {
            ...referencedSheet,
            refVersionId: sheet.referTo.versionId,
          };
        }
        return null;
      });
      const results = await Promise.all(loadSheetTasks);
      for (const result of results) {
        if (result) {
          newReferencedSheets.push(result);
        }
      }
      setReferencedSheets(newReferencedSheets);
      setIsInProcess(false);
    })();
  }, [sheets, versionId, getSheet, isLoadedSheets]);

  return [referencedSheets, !isInProcess && isLoadedSheets];
};
