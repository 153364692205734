import React, { useMemo, ReactNode, createContext, useContext } from "react";

import { FrontendAddonWithTargetVersionRaw } from "@/domain/FrontendAddon";
import { useAPI } from "../hooks";

const frontendAddonCtx = createContext<{
  frontendAddons: FrontendAddonWithTargetVersionRaw[] | null;
  runReloadFrontendAddons: () => Promise<void>;
  isLoadedFrontendAddons: boolean;
}>({
  frontendAddons: [],
  runReloadFrontendAddons: async () => {},
  isLoadedFrontendAddons: false,
});

export const FrontendAddonProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const {
    state: frontendAddons,
    runReloadState: runReloadFrontendAddons,
    isLoadedState: isLoadedFrontendAddons,
  } = useAPI<FrontendAddonWithTargetVersionRaw[]>("frontend_addons_owned");

  const frontendAddonCtxValue = useMemo(
    () => ({
      frontendAddons,
      runReloadFrontendAddons,
      isLoadedFrontendAddons,
    }),
    [frontendAddons, runReloadFrontendAddons, isLoadedFrontendAddons]
  );

  return (
    <frontendAddonCtx.Provider value={frontendAddonCtxValue}>
      {children}
    </frontendAddonCtx.Provider>
  );
};

export const useFrontendAddons = () => {
  return useContext(frontendAddonCtx);
};
