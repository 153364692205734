import { VersionCategoryRaw } from "@/domain/VersionCategories";
import { FontIcon } from "@fluentui/react";
import { Text, Tooltip } from "@fluentui/react-components";
import React, { useMemo } from "react";
import { Version } from "../../../../../../domain/Version";
import styles from "./index.module.css";

type UpdateStatus = "waiting" | "processing" | "success" | "failed";

const statusMap: {
  [key in UpdateStatus]: { class: string; message: string; iconName: string };
} = {
  waiting: {
    class: styles.waiting,
    message: "未実行",
    iconName: "CirclePause",
  },
  processing: {
    class: styles.processing,
    message: "処理中",
    iconName: "Sync",
  },
  success: {
    class: styles.success,
    message: "成功",
    iconName: "Completed",
  },
  failed: {
    class: styles.failed,
    message: "失敗",
    iconName: "ErrorBadge",
  },
};

export type VersionUpdateStatus = {
  versionId: string;
  status: UpdateStatus;
  error?: string;
};

type Props = {
  versions: Version[];
  versionCategories: VersionCategoryRaw[];
  selectedVersionIds: string[];
  updateStatuses: VersionUpdateStatus[];
};

export const StatusList: React.FC<Props> = ({
  versions,
  versionCategories,
  selectedVersionIds,
  updateStatuses,
}) => {
  const versionCategoryMap = useMemo(() => {
    return new Map(
      versionCategories.map((category) => [category.id, category.name])
    );
  }, [versionCategories]);

  return (
    <div className={styles.statusSection}>
      <div className={styles.sectionTitle}>更新ステータス</div>
      <div className={styles.statusListWrapper}>
        <div className={styles.statusListHeader}>
          <div>バージョン名</div>
          <div>カテゴリ</div>
          <div>ステータス</div>
        </div>
        <div className={styles.statusList}>
          {selectedVersionIds.map((versionId) => {
            const version = versions.find((v) => v.id === versionId);
            const categoryName = version?.category
              ? versionCategoryMap.get(version.category) ?? ""
              : "";
            const status =
              updateStatuses.find((s) => s.versionId === versionId)?.status ??
              "waiting";
            const {
              class: statusClass,
              message: statusMessage,
              iconName,
            } = statusMap[status];
            const error = updateStatuses.find(
              (s) => s.versionId === versionId
            )?.error;

            return (
              <div key={versionId} className={styles.statusItem}>
                <Text>{version?.title}</Text>
                <Text>{categoryName}</Text>
                <div className={styles.statusItemStatus}>
                  {status === "failed" ? (
                    <span className={statusClass}>
                      <div className={styles.status}>
                        <Tooltip
                          content={error || ""}
                          relationship="description"
                        >
                          <div className={styles.hoverable}>
                            {statusMessage}
                          </div>
                        </Tooltip>
                        <FontIcon iconName={iconName} />
                      </div>
                    </span>
                  ) : (
                    <span className={statusClass}>
                      <div className={styles.status}>
                        <div>{statusMessage}</div>
                        <FontIcon iconName={iconName} />
                      </div>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
